import { useState } from 'react'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import classNames from 'classnames'
import { LuListFilter } from 'react-icons/lu'
import Menu from '../../utils/Menu'
import MenuItem from '../../utils/Menu/MenuItem'
import { FaCheck } from 'react-icons/fa6'
import Modal from '../../utils/Modal'
import ModalHeader from '../../utils/Modal/ModalHeader'
import ModalCircularProgressHeader from '../../related/ModalCircularProgressHeader'
import ModalBody from '../../utils/Modal/ModalBody'
import Tabs from '../../utils/Tabs'
import Tab from '../../utils/Tabs/Tab'
import { IoMdArrowForward, IoMdFemale, IoMdMale } from 'react-icons/io'
import { BsGenderAmbiguous } from 'react-icons/bs'
import TextField from '../../inputs/TextField'
import { IoSearchOutline } from 'react-icons/io5'
import TabPanel, { tabProps } from '../../utils/Tabs/TabPanel/TabPanel'
import { avatars, femaleAvatars, maleAvatars } from '../../../data/avatars'
import ModalFooter from '../../utils/Modal/ModalFooter'
import Button from '../../inputs/Button'
import styles from './LibraryModal.module.css'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const libraryTabProps = (index) => tabProps(index, 'library')

const LibraryModal = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [showFilters, setShowFilters] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('all')

  const {
    handleModalToggle,
    setCurrentStep,
    handleChangeModalConfig,
    setModalsColor,
    modals: {
      [MODALS_NAMES.LIBRARY]: { type },
    },
  } = useAppModalsContext()

  const onBackClick = () => {
    setCurrentStep((prev) => prev - 1)
    onClose()
    handleModalToggle(MODALS_NAMES.CHOOSE_AVATAR)
  }

  const handleClose = () => {
    setCurrentStep(1)
    onClose()
  }

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleSubmit = () => {
    setCurrentStep((prev) => prev + 1)
    setModalsColor('white')
    handleChangeModalConfig(MODALS_NAMES.WORLD_CONFIGURATION, {
      isStep: true,
    })
    handleModalToggle(MODALS_NAMES.WORLD_CONFIGURATION)
    onClose()
  }

  const tabIconClassNames = (index) =>
    classNames({
      'text-accent': selectedTab === index,
    })

  const filterMenu = () => {
    return (
      <>
        <LuListFilter
          className='h-5 w-5'
          role='button'
          onClick={() => setShowFilters(!showFilters)}
        />
        {showFilters && (
          <Menu
            direction={'right'}
            anchorOrigin={'top-right'}
            className={'mt-4'}
          >
            <MenuItem
              className={classNames('justify-between', {
                active: selectedFilter === 'all',
              })}
              onClick={() => setSelectedFilter('all')}
            >
              <span>Show all</span>
              {selectedFilter === 'all' && <FaCheck />}
            </MenuItem>
            <MenuItem
              className={classNames('justify-between', {
                active: selectedFilter === 'nature',
              })}
              onClick={() => setSelectedFilter('nature')}
            >
              <span>Nature</span>
              {selectedFilter === 'nature' && <FaCheck />}
            </MenuItem>
            <MenuItem
              className={classNames('justify-between', {
                active: selectedFilter === 'indoor',
              })}
              onClick={() => setSelectedFilter('indoor')}
            >
              <span>Indoor spaces</span>
              {selectedFilter === 'indoor' && <FaCheck />}
            </MenuItem>
          </Menu>
        )}
      </>
    )
  }

  return (
    <>
      <Modal open={open} size='xl' onClose={handleClose}>
        <ModalHeader
          open={open}
          onClose={handleClose}
          hasBack
          onBackClick={onBackClick}
          title={
            <ModalCircularProgressHeader
              title={
                type === 'avatars'
                  ? 'Choose avatar from Library'
                  : 'Choose from Ready Player Me'
              }
            />
          }
        />
        <ModalBody>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            tabClassName={'bg-gray-100'}
            rounded={'rounded-lg'}
          >
            <Tab
              label='Male'
              icon={<IoMdMale className={tabIconClassNames(0)} />}
              {...libraryTabProps(0)}
            />
            <Tab
              label='Female'
              icon={<IoMdFemale className={tabIconClassNames(1)} />}
              {...libraryTabProps(1)}
            />
            <Tab
              label='All'
              icon={<BsGenderAmbiguous className={tabIconClassNames(2)} />}
              {...libraryTabProps(2)}
            />
          </Tabs>

          <div className='my-5'>
            <TextField
              placeholder='Search'
              startAdornment={<IoSearchOutline className='h-5 w-5' />}
              endAdornment={filterMenu()}
            />
          </div>

          <TabPanel value={selectedTab} index={0} id='avatars'>
            <div className={styles.tabPanel}>
              {maleAvatars.map((avatar, index) => (
                <div key={index} className={styles.avatarBox}>
                  <img src={avatar.image} alt='avatar' />
                </div>
              ))}
            </div>
          </TabPanel>

          <TabPanel value={selectedTab} index={1} id='avatars'>
            <div className={styles.tabPanel}>
              {femaleAvatars.map((avatar, index) => (
                <div key={index} className={styles.avatarBox}>
                  <img src={avatar.image} alt='avatar' />
                </div>
              ))}
            </div>
          </TabPanel>

          <TabPanel value={selectedTab} index={2} id='avatars'>
            <div className={styles.tabPanel}>
              {avatars.map((avatar, index) => (
                <div key={index} className={styles.avatarBox}>
                  <img src={avatar.image} alt='avatar' />
                </div>
              ))}
            </div>
          </TabPanel>
        </ModalBody>

        <ModalFooter>
          <Button fullWidth className='mt-2' onClick={handleSubmit}>
            <span>Continue</span>
            <IoMdArrowForward />
          </Button>
          {type === 'avatars' && (
            <>
              <div className={styles.dividerBox}>
                <div className={styles.divider}></div>
                <span className='mx-2 font-medium'>or</span>
                <div className={styles.divider}></div>
              </div>

              <Button variant='outlined' fullWidth onClick={handleClose}>
                <span>Import your avatar</span>
                <IoMdArrowForward />
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default LibraryModal
