import { API } from '../utils/constants/app.constants'
import { api } from './api.service'

export const getSettingsPerMetaverse = (metaverseId) => {
  return api
    .get(`${API.settingsPerMetaverse}/${metaverseId}`)
    .then((response) => response.data)
}

export const updateSettingPerMetaverse = (data, metaverseId) => {
  return api
    .put(`${API.settingsPerMetaverse}/${metaverseId}`, data)
    .then((response) => response.data)
}
