export const modalSteps = 4

export const guestRoutes = ['/signup', '/signin']

//baseUrl for api calls
export const BASEURL = "https://api.holofair.io/api/v1"

//api endpoints
export const API = {
  // auth
  signin: `${BASEURL}/signin`,
  signup: `${BASEURL}/signup`,
  user: `${BASEURL}/user`,

  //temps
  temps: `${BASEURL}/temps`,

  //templates
  templates: `${BASEURL}/templates`,

  //metaverses
  metaverses: `${BASEURL}/metaverses`,

  //users
  users: `${BASEURL}/users`,

  //settings
  settings: `${BASEURL}/settings`,

  //invites
  invites: `${BASEURL}/invites`,

  //notifications
  notifications: `${BASEURL}/notifications`,

  //dashboard settings
  dashboardSettings: `${BASEURL}/settings/dashboard`,

  //settings per metaverse
  settingsPerMetaverse: `${BASEURL}/settings/metaverse`,

  //user settings per metaverse
  userSettingsPerMetaverse: `${BASEURL}/settings/user/metaverses`,

  //languages
  languages: `${BASEURL}/languages`,
}

export const AVAILABLE_LOCALES = ['en', 'ar']

export const MODALS_NAMES = {
  NEW_WORLD: 'newWorldModal',
  TEMPLATES: 'templatesModal',
  CHOOSE_AVATAR: 'chooseAvatarModal',
  LIBRARY: 'libraryModal',
  IMPORT_WORLD: 'importWorldModal',
  TEMPLATE_PREVIEW: 'templatePreviewModal',
  WORLD_CONFIGURATION: 'worldConfigurationModal',
  WORLD_SHARE: 'worldShareModal',
}
