import { useEffect, useRef, useState } from 'react'
import { searchEmails } from '../services/metaverse.service'
import { checkValidEmail } from '../utils/helpers/validator.helper'
import { useMutation } from '@tanstack/react-query'
import { sendInvite } from '../services/metaverse-invites.service'
import { getErrorMessage } from '../utils/helpers/error.helper'
import useValidateQuery from './queries/useValidateQuery'
import { QUERY_KEYS } from '../utils/constants/query.constants'
import { useSnackbar } from 'notistack'

const useInviteAutocomplete = ({ worldId, selectedTab }) => {
  const [emailFieldValue, setEmailFieldValue] = useState('')
  const [availableEmails, setAvailableEmails] = useState([])
  const [selectedEmails, setSelectedEmails] = useState([])
  const [isLoadingEmails, setIsLoadingEmails] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const inputContainerRef = useRef(null)
  const dropdownRef = useRef(null)

  const isNotValidEmail =
    emailFieldValue !== '' && !checkValidEmail(emailFieldValue)

  const handleChangeEmailValue = (e) => setEmailFieldValue(e.target.value)

  const handleSelectEmail = (email) => {
    if (selectedEmails.includes(email)) return

    setSelectedEmails((prev) => [...prev, email])

    //clear the field value
    setEmailFieldValue('')
    setAvailableEmails([])
  }

  const handleDeleteEmail = (email) => {
    setSelectedEmails((prev) => prev.filter((e) => e !== email))
  }

  //submit email on enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (emailFieldValue === '') return

      if (selectedTab === 0 && isNotValidEmail) return

      //check if the email is already in the selected emails
      if (selectedEmails.includes(emailFieldValue)) {
        setEmailFieldValue('')
        return
      }

      //if visitor is added, allow any email
      if (selectedTab === 0) {
        setSelectedEmails((prev) => [...prev, emailFieldValue])
      }
      //if collaborator is added, check if email is in the available emails
      else {
        //check if email is in the available emails
        const emailExists = availableEmails.find(
          (user) => user.email === emailFieldValue
        )

        if (!emailExists) {
          setEmailFieldValue('')
          return
        }

        setSelectedEmails((prev) => [...prev, emailExists])
      }

      setEmailFieldValue('')
    }
  }

  const { validateQuery } = useValidateQuery()
  //send invite
  const { mutate, isLoading: isInviting } = useMutation({
    mutationFn: (values) => sendInvite(worldId, values),
    onSuccess: (data) => {
      setSelectedEmails([])
      setAvailableEmails([])
      setEmailFieldValue('')

      validateQuery([QUERY_KEYS.collaborators, worldId.toString()])

      enqueueSnackbar(data?.message || 'Invitation sent!', {
        variant: 'success',
      })
    },
    onError: (error) => {
      console.log('[useInviteAutocomplete] useMutation sendInvite error', error)
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      })
    },
  })

  //fetch emails from the server when the field value changes
  useEffect(() => {
    //get emails from the server
    const fetchEmails = () => {
      setIsLoadingEmails(true)
      searchEmails(emailFieldValue, worldId)
        .then((res) => {
          const emails = res.data?.length > 0 ? res.data : []
          setAvailableEmails(emails)
        })
        .catch((error) => {
          console.log('[useInviteAutocomplete] fetchEmails error', error)
        })
        .finally(() => {
          setIsLoadingEmails(false)
        })
    }

    //if the field is empty, clear the available emails
    if (emailFieldValue === '') {
      setAvailableEmails([])
      return
    }

    //delay the fetch to avoid too many requests
    const timeout = setTimeout(() => {
      fetchEmails()
    }, 500)

    return () => clearTimeout(timeout)
  }, [emailFieldValue, worldId])

  //modify top position of dropdown based on input container height
  //scroll to bottom of input container when new email is added
  useEffect(() => {
    if (inputContainerRef.current) {
      //scroll to bottom
      inputContainerRef.current.scrollTop =
        inputContainerRef.current.scrollHeight
    }

    if (inputContainerRef.current && dropdownRef.current) {
      const inputContainerHeight = inputContainerRef.current.clientHeight
      dropdownRef.current.style.top = `${inputContainerHeight + 12}px`
    }
  }, [inputContainerRef, dropdownRef, selectedEmails, emailFieldValue])

  return {
    inputContainerRef,
    isNotValidEmail,
    dropdownRef,
    emailFieldValue,
    setEmailFieldValue,
    availableEmails,
    setAvailableEmails,
    selectedEmails,
    setSelectedEmails,
    isLoadingEmails,
    mutate,
    isInviting,
    setIsLoadingEmails,
    handleChangeEmailValue,
    handleSelectEmail,
    handleDeleteEmail,
    handleKeyDown,
  }
}

export default useInviteAutocomplete
