import { IoSearchOutline } from 'react-icons/io5'
import TextField from '../../../inputs/TextField'
import InviteAutocomplete from '../InviteAutocomplete'
import WorldShareLink from '../WorldShareLink'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import CircularIOSLoader from '../../../feedback/CircularIOSLoader'
import { LuListFilter } from 'react-icons/lu'
import Menu from '../../../utils/Menu'
import MenuItem from '../../../utils/Menu/MenuItem'
import { FaCheck } from 'react-icons/fa'
import { useBuilderModalsContext } from '../../../../context/BuilderModalsContext'
import UserItem from '../../../related/builder/Header/WorldActionsMenu/UserList/UserItem'

const CollaboratorsTab = ({
  selectedTab,
  isLoading,
  usersList,
  scrollableContainerRef,
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('aToZ')
  const [filteredList, setFilteredList] = useState([])
  const [search, setSearch] = useState('')

  const { selectedWorld, shareModalColor: color } = useBuilderModalsContext()

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)

    //search first name and last name
    const filtered = usersList?.filter(
      (collaborator) =>
        collaborator.user.first_name
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        collaborator.user.last_name.toLowerCase().includes(value.toLowerCase())
    )

    setFilteredList(filtered)
  }

  const filterMenu = () => {
    return (
      <>
        <LuListFilter
          className='h-5 w-5 text-secondary'
          role='button'
          onClick={(e) => {
            e.stopPropagation()
            setShowFilters(!showFilters)
          }}
        />
        {showFilters && (
          <Menu
            direction={'right'}
            anchorOrigin={'bottom-right'}
            className={classNames(
              'mt-4 top-8 !ring-1 !ring-secondary !ring-opacity-10',
              {
                '!bg-primary-light': color !== 'white',
                'text-primary-light': color === 'white',
              }
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem
              theme={color === 'white' ? 'light' : 'dark'}
              className={classNames('justify-between', {
                active: selectedFilter === 'aToZ',
              })}
              onClick={() => setSelectedFilter('aToZ')}
            >
              <span>From A to Z</span>
              {selectedFilter === 'aToZ' && <FaCheck />}
            </MenuItem>
            <MenuItem
              theme={color === 'white' ? 'light' : 'dark'}
              className={classNames('justify-between', {
                active: selectedFilter === 'zToA',
              })}
              onClick={() => setSelectedFilter('zToA')}
            >
              <span>From Z to A</span>
              {selectedFilter === 'zToA' && <FaCheck />}
            </MenuItem>
            <MenuItem
              theme={color === 'white' ? 'light' : 'dark'}
              className={classNames('justify-between', {
                active: selectedFilter === 'dateAdded',
              })}
              onClick={() => setSelectedFilter('dateAdded')}
            >
              <span>Date Added</span>
              {selectedFilter === 'dateAdded' && <FaCheck />}
            </MenuItem>
          </Menu>
        )}
      </>
    )
  }

  useEffect(() => {
    if (usersList) {
      setFilteredList(usersList)
    }
  }, [usersList])
  return (
    <div
      className={classNames('flex flex-col gap-6 mt-6', {
        hidden: selectedTab !== 1,
      })}
    >
      <p className='text-xs'>You can choose from 2 options</p>

      {/* collaborators */}
      <InviteAutocomplete
        key={'collaborators'}
        hidden={selectedTab !== 1}
        selectedTab={selectedTab}
      />
      <WorldShareLink worldId={selectedWorld?.id} color={color} />

      {usersList?.length > 0 && (
        <>
          <p className='text-xs'>Invited collaborators</p>

          <div className='relative'>
            <TextField
              placeholder='Search'
              startAdornment={
                <IoSearchOutline className='h-5 w-5 text-secondary' />
              }
              inputProps={{
                className: classNames('text-white', {
                  '!bg-primary-light !ring-primary-light': color !== 'white',
                  '!bg-neutral-100 !ring-neutral-100': color === 'white',
                }),
              }}
              endAdornment={filterMenu()}
              value={search}
              onChange={handleSearch}
              readOnly={isLoading}
            />
          </div>
        </>
      )}

      <div className='flex flex-col gap-4'>
        {isLoading ? (
          <div className='flex items-center justify-center w-full'>
            <CircularIOSLoader />
          </div>
        ) : filteredList?.length === 0 && search ? (
          <p className='text-sm text-center'>No user found</p>
        ) : (
          <>
            {filteredList?.map((invitedUser) => {
              return (
                <UserItem
                  collaborator={invitedUser}
                  key={invitedUser.id}
                  scrollableContainerRef={scrollableContainerRef}
                  color={color}
                />
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default CollaboratorsTab
