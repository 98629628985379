import { useQueryClient } from '@tanstack/react-query'

const useValidateQuery = () => {
  const queryClient = useQueryClient()

  const validateQuery = (key) => queryClient.invalidateQueries(key)

  return { validateQuery }
}

export default useValidateQuery
