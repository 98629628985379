import { useQuery } from '@tanstack/react-query'
import { getCollaborators } from '../../services/metaverse-invites.service'
import { QUERY_KEYS } from '../../utils/constants/query.constants'

const useCollaboratorsQuery = ({
  metaverseId,
  refetch = false,
  refetchInterval = 10000,
  ...rest
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.collaborators, metaverseId?.toString()],
    queryFn: () => getCollaborators(metaverseId),
    refetchInterval: refetch ? refetchInterval : false,
    ...rest,
  })
}

export default useCollaboratorsQuery
