import styles from './TextField.module.css'
import { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TextField = ({
  startAdornment,
  endAdornment,
  label,
  error,
  helperText,
  containerProps,
  inputProps,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const isCheckboxOrRadio =
    props?.type === 'checkbox' || props?.type === 'radio'

  const containerClasses = classNames(
    styles.root,
    {
      [styles.column]: !isCheckboxOrRadio,
      [styles.chkRad]: isCheckboxOrRadio,
    },
    containerProps?.className
  )

  const labelClasses = classNames(styles.label, {
    [styles.error]: error,
  })

  const inputContainerClasses = classNames(
    styles.inputContainer,
    {
      [styles.textInput]: !isCheckboxOrRadio,
      [styles.focus]: isFocused,
      [styles.error]: error,
    },
    inputProps?.className
  )

  const inputClasses = classNames(styles.input, {
    [styles.checkbox]: props?.type === 'checkbox',
  })

  const helperTextClasses = classNames(styles.helperText, {
    [styles.error]: error,
  })
  return (
    <div className={containerClasses}>
      {label && (
        <label className={labelClasses} htmlFor={props?.id}>
          {label}
        </label>
      )}
      <div
        className={inputContainerClasses}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {startAdornment && <span>{startAdornment}</span>}
        <input className={inputClasses} type={'text'} {...props} />

        {endAdornment && <span>{endAdornment}</span>}
      </div>
      {helperText && <p className={helperTextClasses}>{helperText}</p>}
    </div>
  )
}

TextField.propTypes = {
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  containerProps: PropTypes.object,
  inputProps: PropTypes.object,
}

export default TextField
