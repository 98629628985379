import { useNavigate, useLocation } from 'react-router-dom'
import MainRoutes from './router/Routes'
import { useAuth } from './context/AuthContext'
import { useAppContext } from './context/AppContext'
import { useEffect } from 'react'
import { setupInterceptors } from './services/api.service'

const App = () => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    isInterceptorsLoaded,
    setIsInterceptorsLoaded,
    isRequestsCanceled,
    setIsRequestsCanceled,
  } = useAppContext()

  useEffect(() => {
    if (!isInterceptorsLoaded) {
      setupInterceptors(navigate, location, user, logout)
      setIsInterceptorsLoaded(true)
    }

    if (isRequestsCanceled) {
      setIsRequestsCanceled(false)
      setupInterceptors(navigate, location, user, logout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className='w-full min-h-screen flex items-center'>
      <MainRoutes />
    </div>
  )
}

export default App
