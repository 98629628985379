import PropTypes from 'prop-types'
import styles from './CardImage.module.css'
import classNames from 'classnames'

const CardImage = ({ icon, image, iconNoBg, iconClassName, darkBackdrop }) => {
  return (
    <div className={styles.cardImage}>
      <img src={image} alt='card background' />
      <div
        className={classNames(styles.cardImageOverlay, {
          [styles.noBg]: iconNoBg,
          [styles.darkBackdrop]: darkBackdrop,
        })}
      >
        <div className={classNames(styles.icon, iconClassName)}>{icon}</div>
      </div>
    </div>
  )
}

CardImage.defaultProps = {
  iconNoBg: false,
  darkBackdrop: false,
}

CardImage.propTypes = {
  icon: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  iconNoBg: PropTypes.bool,
  iconClassName: PropTypes.string,
  darkBackdrop: PropTypes.bool,
}

export default CardImage
