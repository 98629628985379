import { useMutation, useQueryClient } from '@tanstack/react-query'
import { removeUser } from '../../services/metaverse-invites.service'
import { QUERY_KEYS } from '../../utils/constants/query.constants'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../utils/helpers/error.helper'
const useCollaboratorRemove = ({ metaverseId, isInvite = 0, ...rest }) => {
  const queryClient = useQueryClient()
  const metaverseIdString = metaverseId?.toString()

  const { enqueueSnackbar } = useSnackbar()

  return useMutation({
    mutationKey: [QUERY_KEYS.collaborators, metaverseIdString],
    mutationFn: (data) => removeUser(metaverseId, data.inviteeId, isInvite),
    onMutate: (variables) => {
      //cancel any outgoing refetches
      queryClient.cancelQueries([QUERY_KEYS.collaborators, metaverseIdString])

      //snapshot the previous value
      const previousCollaborators = queryClient.getQueryData([
        QUERY_KEYS.collaborators,
        metaverseIdString,
      ])

      //optimistically update to the new value
      queryClient.setQueryData(
        [QUERY_KEYS.collaborators, metaverseIdString],
        (old) => {
          return old
            ? {
                ...old,
                data: {
                  ...old.data,
                  collaborators: old.data.collaborators.filter(
                    (collaborator) => collaborator.id !== variables.inviteeId
                  ),
                },
              }
            : old
        }
      )

      //return a context object with the snapshotted value
      return { previousCollaborators }
    },

    onError: (error, _, context) => {
      queryClient.setQueryData(
        [QUERY_KEYS.collaborators, metaverseIdString],
        context.previousCollaborators
      )

      enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
    },
    onSuccess: (data) => {
      enqueueSnackbar(data.message, { variant: 'success' })
    },
    ...rest,
  })
}

export default useCollaboratorRemove
