import { API } from '../utils/constants/app.constants'
import { api } from './api.service'

export const getAvailableLanguages = async () => {
  return api.get(API.languages).then((response) => response.data)
}

export const getMetaverseLanguages = async (metaverseId) => {
  return api
    .get(`${API.metaverses}/${metaverseId}/languages`)
    .then((response) => response.data)
}

export const addAdditionalLanguage = async (metaverseId, languageId) => {
  return api
    .post(`${API.metaverses}/${metaverseId}/languages`, {
      language_id: languageId,
    })
    .then((response) => response.data)
}
