import { QUERY_KEYS } from '../../utils/constants/query.constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getErrorMessage } from '../../utils/helpers/error.helper'
import { updateSettingPerMetaverse } from '../../services/settings-per-metaverse.service'

const useSettingPerMetaverseMutation = ({
  metaverseId,
  appendError,
  ...rest
}) => {
  const queryClient = useQueryClient()

  const metaverseIdInt = parseInt(metaverseId)

  const updateSettingPivotValue = (settings, id, newValue) => {
    const newSettings = settings.map((setting) => {
      if (setting?.pivot.id === id) {
        return {
          ...setting,
          pivot: {
            ...setting.pivot,
            value: newValue,
          },
        }
      }
      return setting
    })

    return newSettings
  }

  return useMutation({
    mutationFn: (data) => updateSettingPerMetaverse(data, metaverseId),
    onMutate: (data) => {
      //cancel any outgoing refetches
      queryClient.cancelQueries([
        QUERY_KEYS.settingsPerMetaverse,
        metaverseIdInt,
      ])

      //snapshot the previous value
      const previousSettings = queryClient.getQueryData([
        QUERY_KEYS.settingsPerMetaverse,
        metaverseIdInt,
      ])

      //optimistically update to the new value
      queryClient.setQueryData(
        [QUERY_KEYS.settingsPerMetaverse, metaverseIdInt],
        (oldData) => {
          return oldData
            ? {
                ...oldData,
                settings: updateSettingPivotValue(
                  oldData.settings,
                  parseInt(data.metaverse_setting_id),
                  data.value
                ),
              }
            : oldData
        }
      )

      //return the snapshotted value
      return { previousSettings }
    },
    onError: (error, _, context) => {
      console.log(
        '[useSettingPerMetaverseMutation] mutation error:',
        getErrorMessage(error)
      )

      queryClient.setQueryData(
        [QUERY_KEYS.settingsPerMetaverse, metaverseIdInt],
        context.previousSettings
      )

      if (appendError) {
        appendError(error)
      }
    },
    ...rest,
  })
}

export default useSettingPerMetaverseMutation
