import { useContext } from 'react'
import { createContext, useState } from 'react'

const AppContext = createContext()

const AppProvider = ({ children }) => {
  const [isInterceptorsLoaded, setIsInterceptorsLoaded] = useState(false)
  const [isRequestsCanceled, setIsRequestsCanceled] = useState(false)
  const [isUnityLoaded, setIsUnityLoaded] = useState(false)
  const [isUnityLoading, setIsUnityLoading] = useState(false)

  return (
    <AppContext.Provider
      value={{
        isInterceptorsLoaded,
        setIsInterceptorsLoaded,
        isRequestsCanceled,
        setIsRequestsCanceled,
        isUnityLoaded,
        setIsUnityLoaded,
        isUnityLoading,
        setIsUnityLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
const useAppContext = () => useContext(AppContext)

export { useAppContext, AppProvider }
