import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { pathConstants } from './pathConstants'

const UnverifiedRoute = () => {
  const { user } = useAuth()
  const location = useLocation()

  return !user?.isVerified ? (
    <Outlet />
  ) : (
    <Navigate to={pathConstants.HOME} state={{ from: location }} />
  )
}

export default UnverifiedRoute
