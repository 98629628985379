import styles from './Menu.module.css'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const Menu = forwardRef(
  ({ children, direction, anchorOrigin, className }, ref) => (
    <div
      ref={ref}
      role='menu'
      tabIndex={-1}
      className={classNames(
        styles.menu,
        styles[direction],
        styles[anchorOrigin],
        className
      )}
    >
      <div className={styles.inner} role='none'>
        {children}
      </div>
    </div>
  )
)

Menu.defaultProps = {
  anchorOrigin: 'top-left',
  direction: 'left',
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right']),
  anchorOrigin: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),
  className: PropTypes.string,
}

export default Menu
