const CustomRadioButton = (props) => {
  return (
    <label className='mt-1'>
      <input type='radio' className='sr-only peer' {...props} />
      <div className="relative w-4 h-4 bg-primary-light rounded-full ring-1 ring-secondary peer-checked:ring-accent cursor-pointer after:content-[''] after:block after:w-2.5 after:h-2.5 after:absolute after:inset-0 after:opacity-0 peer-checked:after:opacity-100 after:bg-accent after:rounded-full after:transform after:translate-x-[0.175rem] rtl:after:translate-x-[-0.175rem] after:translate-y-[0.175rem] after:transition-all after:duration-300"></div>
    </label>
  )
}

export default CustomRadioButton
