import styles from './Card.module.css'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { forwardRef } from 'react'

const Card = forwardRef(({ children, rounded, className, ...props }, ref) => (
  <div
    className={classNames(
      styles.root,
      {
        [styles.rounded]: rounded,
      },
      className
    )}
    ref={ref}
    {...props}
  >
    {children}
  </div>
))

Card.defaultProps = {
  rounded: false,
}

Card.propTypes = {
  rounded: PropTypes.bool,
}

export default Card
