import classNames from 'classnames'
import styles from './CardMedia.module.css'
import propTypes from 'prop-types'

const CardMedia = ({ children, className, image, alt }) => {
  return (
    <div className={classNames(styles.root, className)}>
      {image && <img src={image} alt={alt} />}
      {children}
    </div>
  )
}

CardMedia.defaultProps = {
  className: '',
  image: '',
  alt: '',
}

CardMedia.propTypes = {
  image: propTypes.string,
  alt: propTypes.string,
  className: propTypes.string,
  children: propTypes.node,
}

export default CardMedia
