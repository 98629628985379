import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { cancelAllRequests } from '../services/api.service'
import cookieService from '../services/cookie.service'
import { cookiesNames } from '../utils/constants/cookies.constant'
import { guestRoutes } from '../utils/constants/app.constants'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../utils/constants/query.constants'
import { useNavigate, useLocation } from 'react-router-dom'
import { getUser } from './../services/user.service'
import { useAppContext } from './AppContext'
import { pathConstants } from '../router/pathConstants'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isLogging, setIsLogging] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const { setIsRequestsCanceled } = useAppContext()

  const enabled =
    !user && Boolean(cookieService.getCookie(cookiesNames.ACCESS_TOKEN))

  const { isRefetching: isLoading, refetch } = useQuery({
    queryKey: [QUERY_KEYS.user, user?.id],
    queryFn: getUser,
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setUser(data?.user)
      navigate(location)
    },
    onError: (error) => {
      if (
        error.response?.status === 403 &&
        error.response?.data?.message?.includes('not verified')
      ) {
        if (
          (!guestRoutes.includes(location.pathname) &&
            location.pathname !== pathConstants.EMAIL_VERIFY) ||
          isLogging
        ) {
          navigate(pathConstants.EMAIL_VERIFY, {
            state: { from: location.state?.from },
          })
        }
      } else {
        navigate(pathConstants.SIGNIN, {
          state: { from: location },
        })
      }
    },
  })

  const logout = async () => {
    cancelAllRequests()
    setIsRequestsCanceled(true)
    setUser(null)
    cookieService.removeCookie(cookiesNames.ACCESS_TOKEN)
    navigate(pathConstants.SIGNIN, { state: { from: location } })
  }

  //check session expired
  const checkSessionExpired = useCallback(() => {
    //if there is no token in cookie, set user to null
    const token = cookieService.getCookie(cookiesNames.ACCESS_TOKEN)
    if (!token) {
      setUser(null)
    }
  }, [])

  //check session expired every second
  useEffect(() => {
    checkSessionExpired()

    const timer = setInterval(() => {
      checkSessionExpired()
    }, 1000)

    return () => clearInterval(timer)
  }, [checkSessionExpired])

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        user,
        setUser,
        refetch,
        logout,
        setIsLogging,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
