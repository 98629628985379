import { useSnackbar } from 'notistack'
import { useBuilderModalsContext } from '../../../../context/BuilderModalsContext'
import useSettingPerMetaverseMutation from '../../../../hooks/queries/useSettingPerMetaverseMutation'
import { castStringToBoolean } from '../../../../utils/helpers/string.helper'
import Switch from '../../../inputs/Switch'
import { getErrorMessage } from '../../../../utils/helpers/error.helper'
import builderScreenTranslations from '../../../../locals/builderScreenTranslations.json'

const AvatarConfiguration = ({ findPivotByName }) => {
  const { selectedWorld, worldDefaultLanguage } = useBuilderModalsContext()
  const { enqueueSnackbar } = useSnackbar()

  const { mutate, isLoading } = useSettingPerMetaverseMutation({
    metaverseId: selectedWorld?.id,
    onSuccess: () => {
      enqueueSnackbar('Settings updated successfully', {
        variant: 'success',
      })
    },
    appendError: (error) => {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      })
    },
  })

  const onChangeSwitch = (e) => {
    const { id, checked } = e.target

    mutate({
      metaverse_setting_id: findPivotByName(id)?.id,
      value: checked.toString(),
    })
  }
  return (
    <div className='top-faded-container rounded-lg p-5'>
      <div className='mb-5 text-[15px] text-white text-center'>
        {
          builderScreenTranslations[worldDefaultLanguage?.code]
            .builder_settings_modal.visitors_avatar_options_section.title
        }
      </div>
      <div className='flex flex-col'>
        <div
          className={
            'w-full flex justify-between items-center cursor-pointer pb-2 border-b border-gray mb-5'
          }
        >
          <label htmlFor={'enable_ready_player_me'} className='text-sm'>
            {
              builderScreenTranslations[worldDefaultLanguage?.code]
                .builder_settings_modal.visitors_avatar_options_section
                .enable_ready_player_me_label
            }
          </label>

          <Switch
            id={'enable_ready_player_me'}
            name={'enable_ready_player_me'}
            variant={'black'}
            defaultChecked={castStringToBoolean(
              findPivotByName('enable_ready_player_me')?.value
            )}
            onChange={onChangeSwitch}
            disabled={isLoading}
          />
        </div>

        <div
          className={
            'w-full flex justify-between items-center cursor-pointer pb-2 border-b border-gray mb-5'
          }
        >
          <label htmlFor={'allow_users_avatars'} className='text-sm'>
            {
              builderScreenTranslations[worldDefaultLanguage?.code]
                .builder_settings_modal.visitors_avatar_options_section
                .allow_users_avatars_label
            }
          </label>

          <Switch
            id={'allow_users_avatars'}
            name={'allow_users_avatars'}
            variant={'black'}
            defaultChecked={castStringToBoolean(
              findPivotByName('allow_users_avatars')?.value
            )}
            onChange={onChangeSwitch}
            disabled={isLoading}
          />
        </div>
        <div
          className={
            'w-full flex justify-between items-center cursor-pointer pb-2 border-b border-gray mb-5'
          }
        >
          <label htmlFor={'enable_holofair_avatar_options'} className='text-sm'>
            {
              builderScreenTranslations[worldDefaultLanguage?.code]
                .builder_settings_modal.visitors_avatar_options_section
                .enable_holofair_avatar_options_label
            }
          </label>

          <Switch
            id={'enable_holofair_avatar_options'}
            name={'enable_holofair_avatar_options'}
            variant={'black'}
            checked={castStringToBoolean(
              findPivotByName('enable_holofair_avatar_options')?.value
            )}
            onChange={onChangeSwitch}
            disabled={isLoading}
          />
        </div>

        <label className='flex items-center mb-5 cursor-pointer justify-between w-full'>
          <span className='text-sm font-medium text-gray-300'>
            {
              builderScreenTranslations[worldDefaultLanguage?.code]
                .builder_settings_modal.visitors_avatar_options_section
                .avatar_options_title
            }
          </span>
          <div className='relative inline-flex'>
            <span className='text-sm font-medium text-gray-300'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.visitors_avatar_options_section
                  .avatar_options_view_more
              }
            </span>
          </div>
        </label>
      </div>
    </div>
  )
}

export default AvatarConfiguration
