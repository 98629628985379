import classNames from 'classnames'
import styles from './MenuItem.module.css'
import PropTypes from 'prop-types'

const MenuItem = ({ children, onClick, theme, className, ...props }) => {
  return (
    <button
      role='menuitem'
      className={classNames(styles.menuItem, styles[theme], className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
}

MenuItem.defaultProps = {
  theme: 'light',
}

export default MenuItem
