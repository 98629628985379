import { useState } from 'react'
import { useSnackbar } from 'notistack'

const useCopy = (props) => {
  const [isCopied, setIsCopied] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true)
        props?.onSuccess
          ? props?.onSuccess()
          : enqueueSnackbar('Copied to clipboard', { variant: 'success' })

        setTimeout(() => {
          setIsCopied(false)
        }, 1000)
      })
      .catch((error) => {
        console.log('[useCopy] copy error', error)
        props?.onError
          ? props?.onError()
          : enqueueSnackbar('Error copying to clipboard', { variant: 'error' })
      })
  }

  return { isCopied, copy }
}

export default useCopy
