import styles from './Button.module.css'
import classNames from 'classnames'
import propTypes from 'prop-types'

const Button = ({
  children,
  variant,
  fullWidth,
  color,
  className,
  size,
  radius,
  noPadding,
  ...props
}) => {
  const buttonClasses = classNames(
    styles.root,
    styles[`radius-${radius}`],
    styles[size],
    styles[variant],
    styles[color],
    { [styles.noPadding]: noPadding },
    { [styles.fullWidth]: fullWidth },
    className
  )

  return (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  )
}

Button.propTypes = {
  variant: propTypes.oneOf(['filled', 'outlined', 'text']),
  size: propTypes.oneOf(['base', 'xs', 'sm', 'lg']),
  radius: propTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl', 'full']),
  noPadding: propTypes.bool,
}

Button.defaultProps = {
  variant: 'filled',
  color: 'primary-light',
  size: 'base',
  radius: 'xl',
  noPadding: false,
}

export default Button
