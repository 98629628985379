import { Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/common/Sidebar'
import Header from '../components/common/Header'
import LoadingScreen from '../screens/LoadingScreen'
import { HomeProvider } from '../context/HomeContext'

const AppLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false)

  return (
    <HomeProvider>
      <div className='flex-1 h-full mx-auto bg-white'>
        <Suspense fallback={<LoadingScreen />}>
          <div className='flex h-screen w-screen'>
            <Sidebar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
            <div className='flex flex-col flex-1 relative overflow-y-auto'>
              <Header
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
              />
              <main className='flex-1 overflow-y-auto px-6 py-3'>
                <Outlet />
              </main>
            </div>
          </div>
        </Suspense>
      </div>
    </HomeProvider>
  )
}

export default AppLayout
