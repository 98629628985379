import { createContext, useContext, useState } from 'react'

const HomeContext = createContext()

const HomeProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState('Dashboard')

  return (
    <HomeContext.Provider
      value={{
        headerTitle,
        setHeaderTitle,
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

const useHomeContext = () => {
  const context = useContext(HomeContext)
  if (!context) {
    throw new Error('useHomeContext must be used within a HomeProvider')
  }
  return context
}

export { useHomeContext, HomeProvider }
