import { useMutation } from '@tanstack/react-query'
import useValidateQuery from './useValidateQuery'
import { QUERY_KEYS } from '../../utils/constants/query.constants'
import { deleteMetaverse } from '../../services/metaverse.service'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../utils/helpers/error.helper'

const useDeleteMetaverse = ({ id, onSettled }) => {
  const metaverseId = id?.toString()

  const { validateQuery } = useValidateQuery()
  const { enqueueSnackbar } = useSnackbar()

  const mutation = useMutation({
    mutationKey: [QUERY_KEYS.metaverses, metaverseId],
    mutationFn: () => deleteMetaverse(metaverseId),
    onSettled: (data, error) => {
      if (error) {
        enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
      } else {
        validateQuery([QUERY_KEYS.metaverses])
        validateQuery([QUERY_KEYS.sharedMetaverses])

        onSettled && onSettled()
      }
    },
  })

  return mutation
}

export default useDeleteMetaverse
