import { IoMdClose } from 'react-icons/io'
import Chip from '../../utils/Chip'
import { filters } from '../../../data/templatesFilters'
import Button from '../../inputs/Button'
import classNames from 'classnames'
import styles from './TemplateFilterMenu.module.css'

const TemplateFilterMenu = ({
  open,
  onClose,
  selectedFilters,
  className,
  updateSelectedFilters,
}) => {
  //   const [selectedFilters, setSelectedFilters] = useState([])
  //add filter to selected filters
  const handleSelectFilter = (filter) => {
    const isFilterSelected = selectedFilters.includes(filter)

    if (isFilterSelected) return

    updateSelectedFilters((prev) => [...prev, filter])
  }

  //delete filter from selected filters
  const handleDeleteFilter = (filter) => {
    updateSelectedFilters((prev) => prev.filter((f) => f !== filter))
  }

  return (
    open && (
      <div className={classNames(styles.root, className)}>
        <div className='shrink-0'>
          <div className={styles.header}>
            <h5>Show templates:</h5>
            <IoMdClose onClick={onClose} />
          </div>

          {selectedFilters?.length > 0 && (
            <div className={styles.selectedFiltersContainer}>
              {selectedFilters.map((filter) => (
                <Chip
                  key={filter.label}
                  label={filter.label}
                  variant={'filled'}
                  color={'primary'}
                  onDelete={() => handleDeleteFilter(filter)}
                />
              ))}
            </div>
          )}
        </div>

        <div className={styles.body}>
          {filters.map((filter, index) => (
            <div key={filter.category}>
              <div className='mb-3'>
                <h6>{filter.category}:</h6>
                <div className={styles.fContainer}>
                  {filter.options.map((option, optIndex) => (
                    <Chip
                      key={option.label}
                      label={option.label}
                      onClick={() => handleSelectFilter(option)}
                    />
                  ))}
                </div>
              </div>

              {filters.length - 1 !== index && <hr />}
            </div>
          ))}
        </div>

        <div className={styles.footer}>
          <Button variant={'outlined'} size={'sm'}>
            Reset
          </Button>
          <Button size={'sm'}>Show chosen</Button>
        </div>
      </div>
    )
  )
}

export default TemplateFilterMenu
