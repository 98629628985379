export const images = [
  'https://picsum.photos/id/0/1000/900',
  'https://picsum.photos/id/10/1000/900',
  'https://picsum.photos/id/20/1000/900',
  'https://picsum.photos/id/30/1000/900',
  'https://picsum.photos/id/40/1000/900',
  'https://picsum.photos/id/50/1000/900',
  'https://picsum.photos/id/60/1000/900',
  'https://picsum.photos/id/70/1000/900',
  'https://picsum.photos/id/80/1000/900',
  'https://picsum.photos/id/90/1000/900',
  'https://picsum.photos/id/100/1000/900',
  // 'https://picsum.photos/id/110/1000/900',
  // 'https://picsum.photos/id/120/1000/900',
  // 'https://picsum.photos/id/130/1000/900',
  // 'https://picsum.photos/id/140/1000/900',
  // 'https://picsum.photos/id/150/1000/900',
  // 'https://picsum.photos/id/160/1000/900',
  // 'https://picsum.photos/id/170/1000/900',
  // 'https://picsum.photos/id/180/1000/900',
  // 'https://picsum.photos/id/190/1000/900',
  // 'https://picsum.photos/id/200/1000/900',
  // 'https://picsum.photos/id/210/1000/900',
  // 'https://picsum.photos/id/220/1000/900',
  // 'https://picsum.photos/id/230/1000/900',
  // 'https://picsum.photos/id/240/1000/900',
  // 'https://picsum.photos/id/250/1000/900',
  // 'https://picsum.photos/id/260/1000/900',
]
