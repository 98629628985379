import classNames from 'classnames'

const UnderlinedTabs = ({
  children,
  value,
  onChange,
  activeClass,
  inactiveClass,
  dashClass,
  containerClasses,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-between h-9 gap-2 text-sm border-b-[1px] border-secondary',
        containerClasses
      )}
    >
      {children.map((child, index) => {
        return (
          <button
            key={index}
            className={classNames(
              'text-center w-full h-full flex justify-center',
              {
                'text-accent': index === value,
                'text-lightGray': index !== value,
                [activeClass]: index === value,
                [inactiveClass]: index !== value,
              },
              props?.className
            )}
            onClick={(e) => onChange(e, index)}
            {...props}
            {...child.props}
          >
            <span
              className={classNames(
                'relative h-full w-full block pb-2 font-medium before:max-w-20 before:h-0.5 before:absolute before:mx-auto before:bottom-0 before:left-0 before:right-0 before:bg-accent before:transition-all before:duration-300 before:ease-in-out before:opacity-0',
                {
                  'before:opacity-100': index === value,
                  [dashClass]: index === value,
                }
              )}
            >
              {child.props.label}
            </span>
          </button>
        )
      })}
    </div>
  )
}

export default UnderlinedTabs
