import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import background from '../assets/images/backgrounds/auth-background.jpg'
import whiteLogo from '../assets/images/logo/white-logo.png'
import LoadingScreen from '../screens/LoadingScreen'

const AuthLayout = () => {
  return (
    <div
      className='flex-1 h-full w-full bg-cover bg-center bg-no-repeat'
      style={{ backgroundImage: `url(${background})` }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <div className={`h-full max-w-7xl mx-auto overflow-x-auto`}>
          <div className='flex justify-center flex-col-reverse sm:flex-row min-h-screen p-8 gap-2'>
            <div className='w-full sm:w-1/3 min-w-72 flex flex-col items-center px-8 py-6 bg-white rounded-2xl'>
              <Outlet />
            </div>
            <div className='flex-grow-0 sm:flex-1 flex flex-col items-center justify-center gap-8 px-12'>
              <div className='w-full mb-3 sm:mb-0'>
                <img src={whiteLogo} alt='logo' className='w-full h-full' />
              </div>
              <h1 className='hidden sm:block text-balance text-xl mb-4 md:text-4xl font-bold text-white text-center'>
                Build custom 3D web experience, <br /> from simple to Epic
              </h1>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  )
}

export default AuthLayout
