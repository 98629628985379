import { API } from '../utils/constants/app.constants'
import { api } from './api.service'

export const sendInviteOld = (metaverseId, values) => {
  const formData = new FormData()

  formData.append('email', values.email)
  formData.append('role', values.role)

  return api
    .post(`${API.metaverses}/${metaverseId}/users/invite`, formData)
    .then((response) => response.data)
}

export const sendInvite = (metaverseId, values) => {
  return api
    .post(`${API.metaverses}/${metaverseId}/users/invite`, values)
    .then((response) => response.data)
}

export const updateInvite = (inviteId, values) => {
  const formData = new FormData()
  formData.append('role', values.role)

  return api
    .post(`${API.metaverses}/invites/${inviteId}/update`, formData)
    .then((response) => response.data)
}

export const resendInvite = (inviteId) => {
  return api
    .post(`${API.metaverses}/invites/${inviteId}/resend`)
    .then((response) => response.data)
}

export const getCollaborators = (metaverseId) => {
  return api
    .get(`${API.metaverses}/${metaverseId}/collaborators`)
    .then((response) => response.data)
}

export const blockUser = (metaverse_id, invite_id) => {
  return api
    .post(`${API.metaverses}/${metaverse_id}/invites/${invite_id}/block`)
    .then((response) => response.data)
}

export const unblockUser = (metaverse_id, invite_id) => {
  return api
    .post(`${API.metaverses}/${metaverse_id}/invites/${invite_id}/unblock`)
    .then((response) => response.data)
}

export const removeUser = (metaverse_id, invite_id, isInvite = 0) => {
  return api
    .delete(
      `${API.metaverses}/${metaverse_id}/invites/${invite_id}?isInvite=${isInvite}`
    )
    .then((response) => response.data)
}

//check if the invite is valid
export const checkInvite = (inviteId, metaverseId) => {
  return api
    .get(`${API.metaverses}/${metaverseId}/invites/${inviteId}/check`)
    .then((response) => response.data)
}

//accept invite
export const acceptInvite = (inviteId, metaverseId) => {
  return api
    .put(`${API.metaverses}/${metaverseId}/invites/${inviteId}/accept`)
    .then((response) => response.data)
}

//reject invite
export const rejectInvite = (inviteId, metaverseId) => {
  return api
    .put(`${API.metaverses}/${metaverseId}/invites/${inviteId}/reject`)
    .then((response) => response.data)
}

export const getPendingInvitations = () => {
  return api.get(`${API.invites}/pending`).then((response) => response.data)
}
