import { useEffect, useRef, useState } from 'react'
import Button from '../../inputs/Button'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import propTypes from 'prop-types'
import styles from './Carousel.module.css'
import classNames from 'classnames'

const Carousel = ({
  title,
  children,
  action,
  itemsGap,
  showHeader,
  showControls,
  controlsPosition,
  controlsDistance,
  controlsColor,
  controlsSize,
  roundedControls,
  outerControls,
  showIndicators,
  infiniteScroll,
  className,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const maxScrollWidth = useRef(0)
  const carousel = useRef(null)

  /**
   * @description
   * Handles the previous button click event
   * if the current index is 0 or less then no action is taken
   * else the current index is decremented by 1
   */
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1)
    } else {
      if (infiniteScroll) setCurrentIndex(children.length - 1)
    }
  }

  /**
   * @description
   * Handles the next button click event
   * if the current index * current carousel offset is greater than or equal the max scroll width then no action is taken
   * else the current index is incremented by 1
   */
  const handleNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prev) => prev + 1)
    } else {
      if (infiniteScroll) setCurrentIndex(0)
    }
  }

  /**
   * @description
   * Checks if the carousel buttons should be disabled
   * @param {string} direction - The direction of the button
   * @returns {boolean} - Returns true if the button should be disabled
   * @returns {boolean} - Returns false if the button should not be disabled
   */
  const isDisabled = (direction) => {
    if (infiniteScroll) {
      //if infinite scroll is enabled then the buttons should never be disabled
      return false
    }

    if (direction === 'prev') {
      //less than or equal to 0 then no more previous items
      return currentIndex <= 0
    }

    //if the current index * current carousel offset is greater than or equal the max scroll width then no more next items
    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      )
    }

    return false
  }

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      //scroll to the current index by multiplying the current index by the carousel offset width
      //this will be the value of carousel scroll left to scroll to the current index
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex
    }
  }, [currentIndex])

  useEffect(() => {
    //set the max scroll width to the carousel scroll width minus the carousel offset width
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0
  }, [])

  return (
    <div className={classNames('carousel relative', className)}>
      <div className={`flex justify-between ${showHeader ? 'mb-2' : ''}`}>
        {showHeader && (
          <div className='flex items-center gap-4'>
            <h3 className='text-base font-medium'>{title}</h3>
            {action && action}
          </div>
        )}

        {showControls && (
          <div
            className={classNames(
              styles.controls,
              styles[controlsPosition],
              styles[controlsDistance],
              {
                [styles.outer]: outerControls,
              }
            )}
          >
            <Button
              size={controlsSize}
              color={controlsColor}
              onClick={handlePrev}
              disabled={isDisabled('prev')}
              radius={roundedControls ? 'full' : 'md'}
            >
              <IoIosArrowBack className='w-4 h-4' />
            </Button>
            <Button
              size={controlsSize}
              color={controlsColor}
              onClick={handleNext}
              disabled={isDisabled('next')}
              radius={roundedControls ? 'full' : 'md'}
            >
              <IoIosArrowForward className='w-4 h-4' />
            </Button>
          </div>
        )}
      </div>

      <div
        ref={carousel}
        className={classNames(styles.carouselContainer, `gap-${itemsGap}`)}
      >
        {children}
      </div>

      {showIndicators && (
        <div className={styles.indicatorsContainer}>
          {children?.map((child, index) => {
            return (
              <div
                role='button'
                key={`carousel-dot-${index}`}
                className={classNames(styles.dot, {
                  [styles.active]: currentIndex === index,
                })}
                onClick={() => setCurrentIndex(index)}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

Carousel.propType = {
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  action: propTypes.node,
  itemsGap: propTypes.number,
  showHeader: propTypes.bool,
  roundedControls: propTypes.bool,
  controlsPosition: propTypes.oneOf(['top', 'center', 'bottom']),
  controlsDistance: propTypes.oneOf(['base', 'between']),
  controlsColor: propTypes.oneOf([
    'primary',
    'primary-light',
    'white',
    'secondary',
  ]),
  controlsSize: propTypes.oneOf(['xs', 'sm', 'lg']),
  showIndicators: propTypes.bool,
  showControls: propTypes.bool,
  infiniteScroll: propTypes.bool,
  outerControls: propTypes.bool,
}

Carousel.defaultProps = {
  itemsGap: 5,
  showHeader: true,
  roundedControls: false,
  controlsPosition: 'top',
  controlsDistance: 'base',
  controlsColor: 'primary',
  controlsSize: 'sm',
  showIndicators: false,
  showControls: true,
  infiniteScroll: false,
  outerControls: false,
}

export default Carousel
