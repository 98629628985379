import { useQueryClient, useMutation } from '@tanstack/react-query'
import {
  createMetaverse,
  updateMetaverse,
} from '../../services/metaverse.service'
import { QUERY_KEYS } from '../../utils/constants/query.constants'

const useMutateMetaverse = ({
  isNew,
  metaverse,
  onError,
  onCreateSuccess,
  onUpdateSuccess,
}) => {
  const queryClient = useQueryClient()
  const metaverseId = metaverse?.id.toString()

  return useMutation({
    mutationFn: (values) =>
      isNew ? createMetaverse(values) : updateMetaverse(metaverse?.id, values),
    onMutate: async (newMetaverse) => {
      if (!isNew) {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: [QUERY_KEYS.metaverses, metaverseId],
        })

        // Snapshot the previous value
        const previousMetaverse = queryClient.getQueryData([
          QUERY_KEYS.metaverses,
          metaverseId,
        ])

        // Optimistically update to the new value
        queryClient.setQueryData(
          [QUERY_KEYS.metaverses, metaverseId],
          (old) => {
            return {
              ...old,
              data: {
                ...old.data,
                name: newMetaverse.name,
                description: newMetaverse.description,
              },
            }
          }
        )
        // Return a context with the previous and new metaverse
        return { previousMetaverse, newMetaverse }
      }
    },
    onSettled: (newMetaverse, error, variables, context) => {
      if (error) {
        onError(error)
        if (!isNew) {
          // Rollback to the previous value
          queryClient.setQueryData(
            [QUERY_KEYS.metaverses, metaverseId],
            context.previousMetaverse
          )
        }
      } else {
        if (isNew) {
          onCreateSuccess(newMetaverse)
        } else {
          // Invalidate and refetch
          queryClient.invalidateQueries([QUERY_KEYS.metaverses, metaverseId])
          onUpdateSuccess(newMetaverse)
        }
      }
    },
  })
}

export default useMutateMetaverse
