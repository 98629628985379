export const pathConstants = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
  EMAIL_VERIFY: '/email/verify',
  AUTH_SUCCESS: '/auth-success',
  AUTH_ERROR: '/auth-error',
  GUEST_WORLD: '/guest-world',
  MY_WORLDS: '/worlds/my-worlds',
  SHARED_WORLDS: '/worlds/shared-worlds',
  TEMPLATES: '/templates',
  LEARNING: '/learning',
  HELP_CENTER: '/help/help-center',
  EMAIL_SUPPORT: '/help/email-support',
  SUBSCRIPTION_PLANS: '/subscription-plans',
  SETTINGS: '/settings',
  COMMUNITY: '/community',
  ACCOUNT: '/account',
  BUILDER: '/builder',
  BUILDER_COPY: '/builder-copy',
  BUILDER_PUBLIC: '/builder-public',
  WORLD_ANALYTICS: '/worlds/analytics',
}

export const pathTitles = [
  { title: 'Dashboard', path: pathConstants.HOME },
  { title: 'Sign In', path: pathConstants.SIGNIN },
  { title: 'Sign Up', path: pathConstants.SIGNUP },
  { title: 'Forget Password', path: pathConstants.FORGET_PASSWORD },
  { title: 'Reset Password', path: pathConstants.RESET_PASSWORD },
  { title: 'Email Verify', path: pathConstants.EMAIL_VERIFY },
  { title: 'Auth Success', path: pathConstants.AUTH_SUCCESS },
  { title: 'Auth Error', path: pathConstants.AUTH_ERROR },
  { title: 'Guest World', path: pathConstants.GUEST_WORLD },
  { title: 'My Worlds', path: pathConstants.MY_WORLDS },
  { title: 'Shared Worlds', path: pathConstants.SHARED_WORLDS },
  { title: 'Templates', path: pathConstants.TEMPLATES },
  { title: 'Learning', path: pathConstants.LEARNING },
  { title: 'Help Center', path: pathConstants.HELP_CENTER },
  { title: 'Email Support', path: pathConstants.EMAIL_SUPPORT },
  { title: 'Subscription Plans', path: pathConstants.SUBSCRIPTION_PLANS },
  { title: 'Settings', path: pathConstants.SETTINGS },
  { title: 'Community', path: pathConstants.COMMUNITY },
  { title: 'Account', path: pathConstants.ACCOUNT },
  { title: 'Builder', path: pathConstants.BUILDER },
]
