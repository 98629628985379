export const filters = [
  {
    category: 'Sort by',
    options: [
      {
        label: 'Recently used',
        value: 'recently-used',
      },
      {
        label: 'From A to Z',
        value: 'a-z',
      },
      {
        label: 'From Z to A',
        value: 'z-a',
      },
      {
        label: 'Most popular',
        value: 'most-popular',
      },
    ],
  },
  {
    category: 'Themes',
    options: [
      {
        label: 'See all',
        value: 'see-all',
      },
      {
        label: 'Nature',
        value: 'nature',
      },
      {
        label: 'Games',
        value: 'games',
      },
      {
        label: 'Galleries',
        value: 'galleries',
      },
      {
        label: 'Future',
        value: 'future',
      },
      {
        label: 'Sci-Fi',
        value: 'sci-fi',
      },
      {
        label: 'Cyberpunk',
        value: 'cyberpunk',
      },
      {
        label: 'Urban',
        value: 'urban',
      },
      {
        label: 'Garden',
        value: 'garden',
      },
      {
        label: 'Arboretum',
        value: 'arboretum',
      },
      {
        label: 'Water',
        value: 'water',
      },
      {
        label: 'Floral',
        value: 'floral',
      },
    ],
  },
  {
    category: 'Place',
    options: [
      {
        label: 'City',
        value: 'city',
      },
      {
        label: 'Historical Landmarks',
        value: 'historical-landmarks',
      },
      {
        label: 'Beach',
        value: 'beach',
      },
      {
        label: 'Campus',
        value: 'campus',
      },
      {
        label: 'Sky',
        value: 'sky',
      },
      {
        label: 'Mountains',
        value: 'mountains',
      },
    ],
  },
]
