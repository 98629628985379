import classNames from 'classnames'
import PropTypes from 'prop-types'
import { cloneElement, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import styles from './Avatar.module.css'

const Avatar = ({
  src,
  alt,
  variant,
  className,
  children,
  onClick,
  errorComponent,
  ...props
}) => {
  const [validImage, setValidImage] = useState(true)

  //add classes to the child if it is an icon
  const modifiedChildren = children
    ? typeof children !== 'string'
      ? cloneElement(children, {
          className: classNames(children.props.className, styles.icon),
        })
      : children
    : null

  return (
    <div
      className={classNames(
        styles.root,
        'w-10 h-10',
        {
          'bg-secondary': !src || !validImage,
          'cursor-pointer': onClick,
        },
        styles[variant],
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children ? (
        modifiedChildren
      ) : src ? (
        <>
          {!validImage ? (
            <>
              {errorComponent ? (
                { errorComponent }
              ) : (
                <FaUser className={styles.icon} />
              )}
            </>
          ) : (
            <img src={src} alt={alt} onError={() => setValidImage(false)} />
          )}
        </>
      ) : (
        <>
          {errorComponent ? (
            { errorComponent }
          ) : (
            <FaUser className={styles.icon} />
          )}
        </>
      )}
    </div>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  variant: PropTypes.oneOf(['square', 'rounded', 'circle']),
  children: PropTypes.element,
}

Avatar.defaultProps = {
  variant: 'circle',
}

export default Avatar
