import classNames from 'classnames'
import propTypes from 'prop-types'
import styles from './CardContent.module.css'

const CardContent = ({ children, className }) => {
  const classes = classNames(styles.root, className)
  return <div className={classes}>{children}</div>
}

CardContent.defaultProps = {
  className: '',
}

CardContent.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
}

export default CardContent
