import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import PropTypes from 'prop-types'
import { FiUpload } from 'react-icons/fi'
import { useState } from 'react'
import { FaFile } from 'react-icons/fa'
import classNames from 'classnames'
import styles from './ImportWorldModal.module.css'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const ImportWorldModal = ({ open, onClose }) => {
  const [worldFile, setWorldFile] = useState(null)
  const [dragging, setDragging] = useState(false)

  const { handleModalToggle, setCurrentStep } = useAppModalsContext()

  const onBackClick = () => {
    onClose()
    handleModalToggle(MODALS_NAMES.NEW_WORLD)
  }

  const handleClose = () => {
    setCurrentStep(1)
    onClose()
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    setWorldFile(e.dataTransfer.files[0])
  }

  return (
    <>
      <Modal open={open} size='lg' onClose={handleClose}>
        <ModalHeader
          open={open}
          onClose={handleClose}
          hasBack
          onBackClick={onBackClick}
          title={`Import world`}
        />
        <ModalBody>
          <div
            className={classNames(styles.root, {
              [styles.dragging]: dragging,
            })}
          >
            <input
              type='file'
              id='file'
              className='hidden'
              onChange={(e) => setWorldFile(e.target.files[0])}
            />

            <label
              htmlFor='file'
              className={styles.label}
              role='button'
              draggable
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className={styles.iconBox}>
                <FiUpload />
              </div>

              <p className='text-sm'>Click to browse or drag and drop</p>

              {worldFile && (
                <div className={styles.file}>
                  <FaFile />
                  <p>{worldFile?.name}</p>
                </div>
              )}
            </label>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
ImportWorldModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ImportWorldModal
