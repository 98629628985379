import { useSnackbar } from 'notistack'
import { useBuilderModalsContext } from '../../../../context/BuilderModalsContext'
import useSettingPerMetaverseMutation from '../../../../hooks/queries/useSettingPerMetaverseMutation'
import CustomRadioButton from '../../../inputs/CustomRadioButton'
import { getErrorMessage } from '../../../../utils/helpers/error.helper'
import builderScreenTranslations from '../../../../locals/builderScreenTranslations.json'

const GraphicConfiguration = ({ findPivotByName }) => {
  const { selectedWorld, worldDefaultLanguage } = useBuilderModalsContext()

  const { enqueueSnackbar } = useSnackbar()

  const { mutate, isLoading } = useSettingPerMetaverseMutation({
    metaverseId: selectedWorld?.id,
    onSuccess: () => {
      enqueueSnackbar('Settings updated successfully', {
        variant: 'success',
      })
    },
    appendError: (error) => {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      })
    },
  })

  const onChangeGraphicSetting = (e) => {
    const { value } = e.target
    mutate({
      metaverse_setting_id: findPivotByName('default_graphic_settings')?.id,
      value,
    })
  }

  const selectedGraphicSetting = findPivotByName(
    'default_graphic_settings'
  )?.value

  return (
    <div className='top-faded-container rounded-lg p-2 mb-5'>
      <h5 className='text-sm font-medium text-white mb-2'>
        {
          builderScreenTranslations[worldDefaultLanguage?.code]
            .builder_settings_modal.graphic_settings_section.title
        }
      </h5>

      <div className='flex flex-col gap-4'>
        <div className='flex'>
          <CustomRadioButton
            id='low_quality'
            name='quality'
            value='low_quality'
            defaultChecked={selectedGraphicSetting === 'low_quality'}
            onChange={onChangeGraphicSetting}
            disabled={isLoading}
          />
          <div className='ms-4 text-sm'>
            <label htmlFor='low_quality'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.graphic_settings_section
                  .law_quality_label
              }
            </label>
            <p className='text-xs font-normal text-secondary'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.graphic_settings_section
                  .low_quality_description
              }
            </p>
          </div>
        </div>

        <div className='flex'>
          <CustomRadioButton
            id='optimal_quality'
            name='quality'
            value='optimal_quality'
            defaultChecked={selectedGraphicSetting === 'optimal_quality'}
            onChange={onChangeGraphicSetting}
            disabled={isLoading}
          />
          <div className='ms-4 text-sm'>
            <label htmlFor='optimal_quality'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.graphic_settings_section
                  .optimal_quality_label
              }
            </label>
            <p className='text-xs font-normal text-secondary'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.graphic_settings_section
                  .optimal_quality_description
              }
            </p>
          </div>
        </div>
        <div className='flex'>
          <CustomRadioButton
            id='high_quality'
            name='quality'
            value='high_quality'
            defaultChecked={selectedGraphicSetting === 'high_quality'}
            onChange={onChangeGraphicSetting}
            disabled={isLoading}
          />
          <div className='ms-4 text-sm'>
            <label htmlFor='helper-radio3'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.graphic_settings_section
                  .high_quality_label
              }
            </label>
            <p className='text-xs font-normal text-secondary'>
              {
                builderScreenTranslations[worldDefaultLanguage?.code]
                  .builder_settings_modal.graphic_settings_section
                  .high_quality_description
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraphicConfiguration
