import { IoArrowBackSharp } from 'react-icons/io5'

import { IoMdClose } from 'react-icons/io'
import propTypes from 'prop-types'
import styles from './ModalHeader.module.css'

const ModalHeader = ({ title, onClose, hasBack, onBackClick }) => {
  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        {hasBack && (
          <IoArrowBackSharp className={styles.icon} onClick={onBackClick} />
        )}
        {
          //check if title is a string or a node
          typeof title === 'string' ? (
            <h3 className={styles.title}>{title}</h3>
          ) : (
            title
          )
        }
      </div>
      <IoMdClose className={styles.icon} onClick={onClose} />
    </div>
  )
}

ModalHeader.defaultProps = {
  hasBack: false,
}

ModalHeader.propTypes = {
  title: propTypes.node.isRequired || propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  hasBack: propTypes.bool,
  onBackClick: propTypes.func,
}

export default ModalHeader
