import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import { useBuilderModalsContext } from '../../../context/BuilderModalsContext'
import LanguageConfiguration from './LanguageConfiguration'
import GraphicConfiguration from './GraphicConfiguration'
import AvatarConfiguration from './AvatarConfiguration'
import builderScreenTranslations from '../../../locals/builderScreenTranslations.json'

const BuilderSettingModal = ({ open, onClose, color }) => {
  const { selectedWorld, worldDefaultLanguage } = useBuilderModalsContext()

  const findPivotByName = (name) => {
    return selectedWorld?.settings?.find((setting) => setting.name === name)
      ?.pivot
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='sm'
      color='dark'
      className={'faded-container'}
      //eslint-disable-next-line
      dir={worldDefaultLanguage?.rtl == true ? 'rtl' : 'ltr'}
    >
      <ModalHeader
        onClose={onClose}
        title={
          builderScreenTranslations[worldDefaultLanguage?.code]
            .builder_settings_modal.title
        }
      />
      <ModalBody>
        <LanguageConfiguration findPivotByName={findPivotByName} />

        <GraphicConfiguration findPivotByName={findPivotByName} />

        <AvatarConfiguration findPivotByName={findPivotByName} />
      </ModalBody>
    </Modal>
  )
}

export default BuilderSettingModal
