import classNames from 'classnames'
import PropTypes from 'prop-types'

const Switch = ({ className, label, variant, ...props }) => {
  return (
    <label className='relative inline-flex items-center cursor-pointer'>
      <input type='checkbox' className='sr-only peer' {...props} />
      <div
        className={classNames(
          "w-9 h-5 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full  after:content-[''] after:absolute after:top-[2px] after:start-[2px]  after:border after:rounded-full after:h-4 after:w-4 after:transition-all",
          {
            'peer-checked:bg-accent': variant === 'white',
            'peer-checked:after:bg-accent peer-checked:after:border-accent':
              variant === 'black',
            'bg-neutral-300 peer-checked:after:border-white after:bg-white after:border-gray-300':
              variant === 'white',
            'bg-primary-light peer-checked:after:border-secondary after:bg-secondary after:border-secondary':
              variant === 'black',
          }
        )}
      ></div>
    </label>
  )
}

Switch.propTypes = {
  color: PropTypes.string,
}

Switch.defaultProps = {
  variant: 'white',
}

export default Switch
