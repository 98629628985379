import { API } from '../utils/constants/app.constants'
import { api } from './api.service'

export const getAllNotifications = () => {
  return api.get(`${API.notifications}`).then((response) => response.data)
}

export const markAllNotificationsAsRead = () => {
  return api
    .put(`${API.notifications}/mark-all-as-read`)
    .then((response) => response.data)
}

export const markNotificationAsRead = (id) => {
  return api
    .put(`${API.notifications}/${id}/mark-as-read`)
    .then((response) => response.data)
}

export const deleteNotification = (id) => {
  return api
    .delete(`${API.notifications}/${id}`)
    .then((response) => response.data)
}

export const deleteAllNotifications = () => {
  return api.delete(`${API.notifications}`).then((response) => response.data)
}
