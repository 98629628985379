import classNames from 'classnames'
import propTypes from 'prop-types'
import styles from './Chip.module.css'
import { IoMdClose } from 'react-icons/io'
import { cloneElement } from 'react'

const Chip = ({
  label,
  variant,
  color,
  size,
  icon,
  deleteIcon,
  avatar,
  onClick,
  onDelete,
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        styles[variant],
        styles[color],
        styles[size],
        {
          'cursor-pointer': onClick,
        }
      )}
      onClick={onClick}
    >
      {icon ? icon : avatar ? avatar : null}
      <span className={styles.label}>{label}</span>
      {onDelete &&
        (deleteIcon ? (
          cloneElement(deleteIcon, {
            onClick: onDelete,
            className: styles.deleteIcon,
          })
        ) : (
          <IoMdClose onClick={onDelete} className={styles.deleteIcon} />
        ))}
    </div>
  )
}

Chip.defaultProps = {
  variant: 'outlined',
  color: 'secondary',
  size: 'medium',
}

Chip.propTypes = {
  label: propTypes.string.isRequired,
  variant: propTypes.oneOf(['filled', 'outlined']),
  color: propTypes.oneOf(['primary', 'secondary', 'white', 'accent']),
  size: propTypes.oneOf(['medium', 'small']),
  icon: propTypes.node,
  deleteIcon: propTypes.node,
  avatar: propTypes.node,
  onClick: propTypes.func,
  onDelete: propTypes.func,
}

export default Chip
