import { API } from '../utils/constants/app.constants'
import { api } from './api.service'

//metaverse
export const createMetaverse = (metaverse) => {
  const formData = new FormData()
  if (metaverse.template_id)
    formData.append('template_id', metaverse.template_id)
  formData.append('name', metaverse.name)
  if (metaverse.thumbnail) formData.append('thumbnail', metaverse.thumbnail)
  if (metaverse.description)
    formData.append('description', metaverse.description)

  return api
    .post(API.metaverses, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
}

export const getMetaversesByUser = (limit = null) => {
  let url = `${API.metaverses}/user`

  if (limit) url += `?limit=${limit}`

  return api.get(url).then((response) => response.data)
}

export const getMetaverseById = (id) => {
  return api.get(`${API.metaverses}/${id}`).then((response) => response.data)
}

export const getMetaversePublic = (id) => {
  return api
    .get(`${API.metaverses}/${id}/public`)
    .then((response) => response.data)
}

export const updateMetaverse = (id, metaverse) => {
  const formData = new FormData()
  if (metaverse.name) formData.append('name', metaverse.name)
  if (metaverse.description)
    formData.append('description', metaverse.description)
  if (metaverse.thumbnail) formData.append('thumbnail', metaverse.thumbnail)

  return api
    .post(`${API.metaverses}/${id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
}

export const getSharedMetaverses = (limit = null) => {
  let url = `${API.metaverses}/shared`
  if (limit) url += `?limit=${limit}`
  return api.get(url).then((response) => response.data)
}

export const deleteMetaverse = (id) => {
  return api.delete(`${API.metaverses}/${id}`).then((response) => response.data)
}

export const searchEmails = (searchKey, metaverse_id) => {
  return api
    .get(`${API.metaverses}/${metaverse_id}/emails/search?search=${searchKey}`)
    .then((response) => response.data)
}

export const checkUniqueness = (name) => {
  return api
    .get(`${API.metaverses}/check-uniqueness?name=${name}`)
    .then((response) => response.data)
}

export const addLink = (metaverse_id, values) => {
  const formData = new FormData()

  formData.append('name', values.name)
  formData.append('url', values.url)

  return api
    .post(`${API.metaverses}/${metaverse_id}/links`, formData)
    .then((response) => response.data)
}
