import classNames from 'classnames'
import Avatar from '../../../utils/Avatar'
import { useNavigate } from 'react-router-dom'
import { GoDotFill } from 'react-icons/go'
import moment from 'moment'
import { useMutation } from '@tanstack/react-query'
import { markNotificationAsRead } from '../../../../services/notifications.service'
import useValidateQuery from '../../../../hooks/queries/useValidateQuery'
import { QUERY_KEYS } from '../../../../utils/constants/query.constants'

const DashboardNotificationItem = ({ notification }) => {
  const navigate = useNavigate()

  const { validateQuery } = useValidateQuery()

  //mark single notification as read
  const { mutate } = useMutation({
    mutationKey: ['markNotificationAsRead', notification.id],
    mutationFn: () => markNotificationAsRead(notification.id),
    onSuccess: (_) => validateQuery([QUERY_KEYS.notifications]),
  })

  //handle click notification
  const handleOnClick = () => {
    mutate()

    if (notification.data?.path) {
      navigate(notification.data.path)
    }
  }

  return (
    <div
      key={notification.id}
      className={classNames('px-6 py-4', {
        // 'bg-neutral-100': !notification.read_at,
        'cursor-pointer': notification.data?.path,
      })}
      onClick={handleOnClick}
    >
      <div className='flex gap-4 items-start'>
        <Avatar
          src={`${process.env.REACT_APP_BACKEND_URL}/${notification.data.sender.avatar}`}
          alt={`${notification.data.sender.first_name} ${notification.data.sender.last_name}`}
          className='h-10 w-10 ring-1 ring-secondary/25'
        />
        <div className='flex-1 flex flex-col gap-2'>
          <h4 className='text-sm font-bold'>{notification.data.title}</h4>
          <p className='text-sm'>{notification.data.message}</p>
          <p className='text-xs text-gray-400 flex gap-1 items-center'>
            {!notification.read_at && (
              <span className='text-red-600 text-[0.625rem]'>
                <GoDotFill />
              </span>
            )}
            <span>{moment(notification.created_at).format('DD MMM YYYY')}</span>
            <span className='text-[0.425rem]'>
              <GoDotFill />
            </span>
            <span>{moment(notification.created_at).fromNow()}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default DashboardNotificationItem
