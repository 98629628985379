import { useState } from 'react'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import classNames from 'classnames'
import Modal from '../../utils/Modal'
import ModalHeader from '../../utils/Modal/ModalHeader'
import ModalBody from '../../utils/Modal/ModalBody'
import Tabs from '../../utils/Tabs'
import Tab from '../../utils/Tabs/Tab'
import { IoMdArrowForward, IoMdFemale, IoMdMale } from 'react-icons/io'
import TabPanel, { tabProps } from '../../utils/Tabs/TabPanel/TabPanel'
import { femaleAvatars, maleAvatars } from '../../../data/avatars'
import ModalFooter from '../../utils/Modal/ModalFooter'
import Button from '../../inputs/Button'
import styles from './WorldAvatarModal.module.css'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const libraryTabProps = (index) => tabProps(index, 'library')

const WorldAvatarModal = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const { handleModalToggle, setCurrentStep } = useAppModalsContext()

  const onBackClick = () => {
    setCurrentStep((prev) => prev - 1)
    onClose()
    handleModalToggle(MODALS_NAMES.CHOOSE_AVATAR)
  }

  const handleClose = () => {
    setCurrentStep(1)
    onClose()
  }

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const tabIconClassNames = (index) =>
    classNames({
      'text-accent': selectedTab === index,
    })

  return (
    <>
      <Modal open={open} size='xl' onClose={handleClose} color='primary'>
        <ModalHeader
          open={open}
          onClose={handleClose}
          onBackClick={onBackClick}
          title={'Choose avatar'}
        />
        <ModalBody>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            tabClassName={'bg-primary-light p-0.5'}
            rounded={'rounded-xl'}
            tabColor={{
              selected: 'darkGray',
              unselected: 'secondary',
            }}
          >
            <Tab
              label='Male'
              icon={<IoMdMale className={tabIconClassNames(0)} />}
              {...libraryTabProps(0)}
            />
            <Tab
              label='Female'
              icon={<IoMdFemale className={tabIconClassNames(1)} />}
              {...libraryTabProps(1)}
            />
          </Tabs>
          <br></br>
          <TabPanel value={selectedTab} index={0} id='avatars'>
            <div className={styles.tabPanel}>
              {maleAvatars.map((avatar, index) => (
                <div key={index} className={styles.avatarBox}>
                  <img src={avatar.image} alt='avatar' />
                </div>
              ))}
            </div>
          </TabPanel>

          <TabPanel value={selectedTab} index={1} id='avatars'>
            <div className={styles.tabPanel}>
              {femaleAvatars.map((avatar, index) => (
                <div key={index} className={styles.avatarBox}>
                  <img src={avatar.image} alt='avatar' />
                </div>
              ))}
            </div>
          </TabPanel>
        </ModalBody>

        <ModalFooter>
          <Button
            fullWidth
            color='white'
            className='mt-2'
            onClick={handleClose}
          >
            <span>Continue</span>
            <IoMdArrowForward />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default WorldAvatarModal
