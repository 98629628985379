import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import NewWorldModal from '../components/modals/NewWorldModal'
import TemplatesModal from '../components/modals/TemplatesModal'
import { MODALS_NAMES, modalSteps } from '../utils/constants/app.constants'
import ImportWorldModal from '../components/modals/ImportWorldModal'
import ChooseAvatarModal from '../components/modals/ChooseAvatarModal/ChooseAvatarModal'
import LibraryModal from '../components/modals/LibraryModal' // LibraryModal
import TemplatePreviewModal from '../components/modals/TemplatePreviewModal'
import WorldConfigurationModal from '../components/modals/WorldConfigurationModal'

const AppModalsContext = createContext()

const AppModalsProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [modalsColor, setModalsColor] = useState('dark')
  const [modals, setModals] = useState({
    newWorldModal: {
      open: false,
    },
    templatesModal: {
      open: false,
    },
    chooseAvatarModal: {
      open: false,
    },
    libraryModal: {
      open: false,
      type: 'avatars',
    },
    importWorldModal: {
      open: false,
    },
    templatePreviewModal: {
      open: false,
    },
    worldConfigurationModal: {
      open: false,
      isStep: false,
      selectedWorld: null,
      selectedTemplate: null,
    },
    worldShareModal: {
      open: false,
      selectedWorld: null,
    },
  })
  const stepsPercentage = (currentStep / modalSteps) * 100

  const handleModalToggle = useCallback((modalName) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: {
        ...prev[modalName],
        open: !prev[modalName].open,
      },
    }))
  }, [])

  const handleChangeModalConfig = useCallback((modalName, config) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: {
        ...prev[modalName],
        ...config,
      },
    }))
  }, [])

  const contextValue = useMemo(
    () => ({
      modals,
      handleModalToggle,
      handleChangeModalConfig,
      currentStep,
      setCurrentStep,
      stepsPercentage,
      modalsColor,
      setModalsColor,
    }),
    [
      modals,
      handleModalToggle,
      handleChangeModalConfig,
      currentStep,
      stepsPercentage,
      modalsColor,
    ]
  )

  useEffect(() => {
    if (currentStep < 1) {
      setCurrentStep(1)
    } else if (currentStep > modalSteps) {
      setCurrentStep(modalSteps)
    }
  }, [currentStep])

  return (
    <AppModalsContext.Provider value={contextValue}>
      {children}

      <NewWorldModal
        open={modals.newWorldModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.NEW_WORLD)}
      />

      <TemplatesModal
        open={modals.templatesModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.TEMPLATES)}
      />

      <ChooseAvatarModal
        open={modals.chooseAvatarModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.CHOOSE_AVATAR)}
      />

      <LibraryModal
        open={modals.libraryModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.LIBRARY)}
      />

      <ImportWorldModal
        open={modals.importWorldModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.IMPORT_WORLD)}
      />

      <TemplatePreviewModal
        open={modals.templatePreviewModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.TEMPLATE_PREVIEW)}
      />

      <WorldConfigurationModal
        open={modals.worldConfigurationModal.open}
        onClose={() => handleModalToggle(MODALS_NAMES.WORLD_CONFIGURATION)}
      />
    </AppModalsContext.Provider>
  )
}

const useAppModalsContext = () => {
  const context = useContext(AppModalsContext)

  // Throw an error if the hook is not used within a AppModalsProvider
  if (context === undefined) {
    throw new Error(
      'useAppModalsContext must be used within a AppModalsProvider'
    )
  }
  return context
}

export { AppModalsProvider, useAppModalsContext }
