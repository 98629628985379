import SquarePlaceholder from '../../../assets/images/square-placeholder.png'
import Card from '../../utils/Card'
import CardActions from '../../utils/Card/CardActions'
import CardMedia from '../../utils/Card/CardMedia'
import Button from '../../inputs/Button'
import PropTypes from 'prop-types'
import { IoMdArrowForward } from 'react-icons/io'
import styles from './NewWorldOptionCard.module.css'

const NewWorldOptionCard = ({ actionText, description, image, onClick }) => {
  return (
    <Card className={styles.root}>
      <CardMedia alt={actionText} className={styles.media}>
        {image}
      </CardMedia>
      <CardActions className='!p-0'>
        <Button
          fullWidth
          onClick={onClick}
          radius={'lg'}
          color={'primary-light'}
          className={'text-sm'}
        >
          <span>{actionText}</span>
          <IoMdArrowForward />
        </Button>
      </CardActions>
    </Card>
  )
}

NewWorldOptionCard.defaultProps = {
  image: <img src={SquarePlaceholder} alt='placeholder' />,
}

NewWorldOptionCard.propTypes = {
  actionText: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.node,
  onClick: PropTypes.func,
}

export default NewWorldOptionCard
