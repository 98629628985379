import { createContext, useContext, useMemo, useState } from 'react'
import BuilderSettingModal from '../components/modals/BuilderSettingModal'
import WorldAvatarModal from '../components/modals/WorldAvatarModal'
import { AVAILABLE_LOCALES } from '../utils/constants/app.constants'
import WorldShareModal from '../components/modals/WorldShareModal'

const BuilderModalsContext = createContext()

const BuilderModalsProvider = ({ children }) => {
  const [selectedWorld, setSelectedWorld] = useState(null)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [shareModalColor, setShareModalColor] = useState('primary')
  const [openBuilderSettingModal, setOpenBuilderSettingModal] = useState(false)
  const [libraryOpen, setLibraryOpen] = useState(false)
  const handleOpenShareModal = () => setOpenShareModal(true)
  const handleCloseShareModal = () => setOpenShareModal(false)
  const handleOpenBuilderSettingModal = () => setOpenBuilderSettingModal(true)
  const handleCloseBuilderSettingModal = () => setOpenBuilderSettingModal(false)
  const handleCloseLibraryModal = () => setLibraryOpen(false)
  const handleOpenLibraryModal = () => setLibraryOpen(true)

  const worldDefaultLanguageCode = useMemo(() => {
    return selectedWorld?.mapped_user_settings?.find(
      (item) => item.name === 'default_language'
    )?.userValue
  }, [selectedWorld])

  const worldDefaultLanguage = useMemo(() => {
    return selectedWorld?.languages?.find(
      (lang) => lang.code === worldDefaultLanguageCode
    )
  }, [worldDefaultLanguageCode, selectedWorld])

  //check if language exists in the locals
  //TODO: to be removed
  const isLanguageAvailable = useMemo(
    () => AVAILABLE_LOCALES.includes(worldDefaultLanguageCode),
    [worldDefaultLanguageCode]
  )

  const language = useMemo(() => {
    return isLanguageAvailable
      ? worldDefaultLanguage
      : { code: 'en', name: 'English', rtl: false }
  }, [worldDefaultLanguage, isLanguageAvailable])

  return (
    <BuilderModalsContext.Provider
      value={{
        openShareModal,
        handleOpenShareModal,
        handleCloseShareModal,
        openBuilderSettingModal,
        handleOpenBuilderSettingModal,
        handleCloseBuilderSettingModal,
        libraryOpen,
        handleCloseLibraryModal,
        handleOpenLibraryModal,
        shareModalColor,
        setShareModalColor,
        selectedWorld,
        setSelectedWorld,
        worldDefaultLanguage: language,
      }}
    >
      {children}

      <WorldShareModal open={openShareModal} onClose={handleCloseShareModal} />

      <BuilderSettingModal
        open={openBuilderSettingModal}
        onClose={handleCloseBuilderSettingModal}
        color={shareModalColor}
      />

      <WorldAvatarModal
        open={libraryOpen}
        onClose={() => setLibraryOpen(false)}
      />
    </BuilderModalsContext.Provider>
  )
}

const useBuilderModalsContext = () => {
  const context = useContext(BuilderModalsContext)
  if (context === undefined) {
    throw new Error(
      'useBuilderModalsContext must be used within a BuilderModalsProvider'
    )
  }
  return context
}

export { BuilderModalsProvider, useBuilderModalsContext }
