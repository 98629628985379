import { useEffect, useRef, useState } from 'react'
import Avatar from '../../../../../../utils/Avatar'
import { firstLetterToUpperCase } from '../../../../../../../utils/helpers/string.helper'
import { IoEllipsisVerticalCircle } from 'react-icons/io5'
import { IoMdClose } from 'react-icons/io'
import { MdBlockFlipped } from 'react-icons/md'
import useCollaboratorBlock from '../../../../../../../hooks/queries/useCollaboratorBlock'
import ConfirmDialogModal from '../../../../../../modals/ConfirmDialogModal'
import useCollaboratorRemove from '../../../../../../../hooks/queries/useCollaboratorRemove'
import classNames from 'classnames'
import { useBuilderModalsContext } from '../../../../../../../context/BuilderModalsContext'

const UserItem = ({ collaborator, scrollableContainerRef, color }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedAction, setSelectedAction] = useState(null)

  const menuRef = useRef()

  //user data
  const username = collaborator.is_external
    ? collaborator.user
    : firstLetterToUpperCase(
        `${collaborator?.user?.first_name} ${collaborator?.user?.last_name}`
      )

  const userAvatar = collaborator.is_external
    ? null
    : collaborator?.user?.avatar

  //check if user can edit the world (remove, block/unblock)
  const { selectedWorld: metaverse } = useBuilderModalsContext()
  const canEdit =
    (metaverse?.is_collaborator || metaverse?.is_owner) &&
    !metaverse?.is_blocked

  //dialog data
  const dialogText =
    selectedAction === 'block'
      ? `Are you sure you want to block ${collaborator?.user?.first_name} ${collaborator?.user?.last_name}?`
      : selectedAction === 'unblock'
      ? `Are you sure you want to unblock ${collaborator?.user?.first_name} ${collaborator?.user?.last_name}?`
      : `Are you sure you want to remove ${collaborator?.user?.first_name} ${collaborator?.user?.last_name}?`

  const toggleMenu = () => setShowMenu((prev) => !prev)

  const onConfirmDialog = () => {
    if (selectedAction === 'block' || selectedAction === 'unblock') {
      mutate({ inviteeId: collaborator.id })
    }

    if (selectedAction === 'remove') {
      removeCollaboratorMutation({ inviteeId: collaborator.id })
    }
  }

  const { mutate, isLoading } = useCollaboratorBlock({
    metaverseId: metaverse?.id,
    isUnblock: selectedAction === 'unblock',
  })

  const { mutate: removeCollaboratorMutation, isLoading: isRemoving } =
    useCollaboratorRemove({
      metaverseId: metaverse?.id,
    })

  //hide menu when clicking outside
  useEffect(() => {
    const handleClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target) && showMenu) {
        setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [showMenu])

  //use scrollableContainerRef to toggle overflow-y-auto in scrollable part to float the menu outside the scrollable container
  useEffect(() => {
    if (!scrollableContainerRef?.current) return
    if (showMenu) {
      scrollableContainerRef.current.classList.remove('overflow-y-auto')
    } else {
      scrollableContainerRef.current.classList.add('overflow-y-auto')
    }
  }, [showMenu, scrollableContainerRef])

  return (
    <>
      <div
        className={classNames(
          'flex items-center justify-between py-1 px-2 rounded-lg relative',
          {
            'bg-primary-light': color !== 'white',
            'bg-white': color === 'white',
          }
        )}
      >
        <div className='flex-1 flex items-center gap-4'>
          <Avatar className={'w-8 h-8'} src={userAvatar} alt={username} />
          <p className='text-sm'>{username}</p>
        </div>
        {canEdit && (
          <div
            className={classNames('relative', {
              'cursor-not-allowed': isLoading || isRemoving,
            })}
            ref={menuRef}
            // onClick={(e) => e.stopPropagation()}
          >
            <IoEllipsisVerticalCircle
              className='text-2xl'
              onClick={() => {
                if (isLoading || isRemoving) return
                toggleMenu()
              }}
            />
            {showMenu && (
              <div
                className={classNames(
                  'absolute top-7 left-0 z-50 ring-1 ring-secondary ring-opacity-10 rounded-md p-2 min-w-36',
                  {
                    'bg-primary-light text-white': color !== 'white',
                    'bg-white text-primary-light': color === 'white',
                  }
                )}
              >
                <div
                  className='flex items-center gap-4 cursor-pointer mb-2'
                  role='button'
                  onClick={() => {
                    setSelectedAction('remove')
                    setOpenDialog(true)
                  }}
                >
                  <IoMdClose className='text-sm' />
                  <span className='text-xs'>Remove</span>
                </div>
                {!collaborator.is_external && (
                  <div
                    className='flex items-center gap-4 cursor-pointer'
                    role='button'
                    onClick={() => {
                      setSelectedAction(
                        collaborator?.status === 'blocked' ? 'unblock' : 'block'
                      )
                      setOpenDialog(true)
                    }}
                  >
                    <MdBlockFlipped className='text-sm' />
                    <span className='text-xs'>
                      {collaborator?.status === 'blocked' ? 'Unblock' : 'Block'}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <ConfirmDialogModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={`Confirm ${firstLetterToUpperCase(selectedAction)}`}
        text={dialogText}
        onConfirm={onConfirmDialog}
      />
    </>
  )
}

UserItem.defaultProps = {
  color: 'primary',
}

export default UserItem
