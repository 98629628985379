export const firstLetterToUpperCase = (string) => {
  if (!string) return ''

  const words = string.split(' ')
  const newWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  return newWords.join(' ')
}

export const castStringToBoolean = (value) => {
  if (typeof value === 'string') {
    if (value === 'true') {
      return true
    } else {
      return false
    }
  } else if (typeof value === 'boolean') {
    return value
  } else {
    return false
  }
}
