import { Routes, Route } from 'react-router-dom'
import GuestRoute from './GuestRoute'
import AuthLayout from '../layout/AuthLayout'
import ProtectedRoute from './ProtectedRoute'
import { pathConstants } from './pathConstants'
import UnverifiedRoute from './UnverifiedRoute'
import { lazy } from 'react'
import AppLayout from '../layout/AppLayout'
import BuilderLayout from '../layout/BuilderLayout'
import EmptyLayout from '../layout/EmptyLayout'

//public routes
const PublicBuilderScreen = lazy(() => import('../screens/PublicBuilderScreen'))

//auth routes
const AuthScreen = lazy(() => import('../screens/auth/AuthScreen'))
const ForgetPasswordScreen = lazy(() =>
  import('../screens/auth/ForgetPasswordScreen')
)
const ResetPasswordScreen = lazy(() =>
  import('../screens/auth/ResetPasswordScreen')
)
const SuccessScreen = lazy(() => import('../screens/auth/SuccessScreen'))
const ErrorScreen = lazy(() => import('../screens/auth/ErrorScreen'))
const EmailVerificationScreen = lazy(() =>
  import('../screens/auth/EmailVerificationScreen')
)

//app routes
const DashboardScreen = lazy(() => import('../screens/DashboardScreen'))
const MyWorldsScreen = lazy(() => import('../screens/MyWorldsScreen'))
const SharedWorldsScreen = lazy(() => import('../screens/SharedWorldsScreen'))
const TemplatesScreen = lazy(() => import('../screens/TemplatesScreen'))
const AccountScreen = lazy(() => import('../screens/AccountScreen'))
const SettingsScreen = lazy(() => import('../screens/SettingsScreen'))
const SubscriptionPlansScreen = lazy(() =>
  import('../screens/SubscriptionPlansScreen')
)
const WorldAnalyticsScreen = lazy(() =>
  import('../screens/WorldAnalyticsScreen')
)

//builder routes
const BuilderScreen = lazy(() => import('../screens/BuilderScreen'))
const BuilderScreenCopy = lazy(() => import('../screens/BuilderScreenCopy'))

const ControlInvitationsScreen = lazy(() =>
  import('../screens/ControlInvitationsScreen')
)

const MainRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route
        path={pathConstants.BUILDER_PUBLIC}
        element={<PublicBuilderScreen />}
      />

      {/* Guest routes */}
      <Route element={<GuestRoute />}>
        <Route element={<AuthLayout />}>
          <Route path={pathConstants.SIGNIN} element={<AuthScreen />} />
          <Route
            path={pathConstants.SIGNUP}
            element={<AuthScreen isSignin={false} />}
          />
          <Route
            path={pathConstants.FORGET_PASSWORD}
            element={<ForgetPasswordScreen />}
          />
          <Route
            path={pathConstants.RESET_PASSWORD}
            element={<ResetPasswordScreen />}
          />
          <Route
            path={pathConstants.AUTH_SUCCESS}
            element={<SuccessScreen />}
          />
          <Route path={pathConstants.AUTH_ERROR} element={<ErrorScreen />} />
        </Route>
      </Route>

      {/* admin and user routes */}
      <Route element={<ProtectedRoute allowedRoles={['admin', 'user']} />}>
        <Route element={<AppLayout />}>
          <Route path={pathConstants.HOME} element={<DashboardScreen />} />
          <Route path={pathConstants.MY_WORLDS} element={<MyWorldsScreen />} />
          <Route
            path={pathConstants.SHARED_WORLDS}
            element={<SharedWorldsScreen />}
          />
          <Route path={pathConstants.TEMPLATES} element={<TemplatesScreen />} />
          <Route path={pathConstants.LEARNING} element={<div>Learning</div>} />
          <Route
            path={pathConstants.HELP_CENTER}
            element={<div>Help center</div>}
          />
          <Route
            path={pathConstants.EMAIL_SUPPORT}
            element={<div>Email support</div>}
          />
          <Route
            path={pathConstants.SUBSCRIPTION_PLANS}
            element={<SubscriptionPlansScreen />}
          />
          <Route path={pathConstants.SETTINGS} element={<SettingsScreen />} />
          <Route
            path={pathConstants.COMMUNITY}
            element={<div>Community</div>}
          />
          <Route path={pathConstants.ACCOUNT} element={<AccountScreen />} />

          <Route
            path={`${pathConstants.WORLD_ANALYTICS}/:worldId`}
            element={<WorldAnalyticsScreen />}
          />
        </Route>

        <Route element={<BuilderLayout />}>
          <Route
            path={`${pathConstants.BUILDER}/:worldId`}
            element={<BuilderScreen />}
          />
          <Route
            path={`${pathConstants.BUILDER_COPY}/:worldId`}
            element={<BuilderScreenCopy />}
          />
        </Route>

        <Route element={<EmptyLayout />}>
          <Route
            path='/metaverses/:worldId/invitations/:inviteId'
            element={<ControlInvitationsScreen />}
          />
        </Route>
      </Route>

      {/* admin routes */}
      <Route element={<ProtectedRoute allowedRoles={['admin']} />}></Route>

      {/* user routes */}
      <Route element={<ProtectedRoute allowedRoles={['user']} />}></Route>

      {/* email verification routes*/}
      <Route element={<UnverifiedRoute />}>
        <Route element={<AuthLayout />}>
          <Route
            path={pathConstants.EMAIL_VERIFY}
            element={<EmailVerificationScreen />}
          />
        </Route>
      </Route>

      {/* 404 */}
      <Route path='*' element={<div>Not found</div>} />
    </Routes>
  )
}

export default MainRoutes
