import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import { IoArrowForward } from 'react-icons/io5'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import propTypes from 'prop-types'
import { FaImage } from 'react-icons/fa'
import Button from '../../inputs/Button'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const TemplatePreviewModal = ({ open, onClose }) => {
  const { handleModalToggle, setCurrentStep } = useAppModalsContext()

  const onUseTemplateClick = () => {
    setCurrentStep(2)
    onClose()
    handleModalToggle(MODALS_NAMES.CHOOSE_AVATAR)
  }

  return (
    <>
      <Modal open={open} onClose={onClose} size='xxxxxl'>
        <ModalHeader
          onClose={onClose}
          hasBack
          title='Template details'
          onBackClick={onClose}
        />
        <ModalBody>
          {open && (
            <div className='flex gap-6'>
              <div className='h-96 bg-gray-200 w-2/3 flex justify-center items-center overflow-hidden rounded-xl'>
                <FaImage className='text-2xl' />
              </div>
              <div className=''>
                <h2 className='text-2xl font-bold mb-4'>Template name</h2>
                <p className='text-sm mb-4'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quibusdam, voluptatum.
                </p>
                <Button
                  fullWidth
                  size={'sm'}
                  radius={'lg'}
                  className={'text-sm'}
                  onClick={onUseTemplateClick}
                >
                  <span className='text-inherit'>Use Template</span>
                  <IoArrowForward className='text-lg' />
                </Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

TemplatePreviewModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
}

export default TemplatePreviewModal
