import { useAppModalsContext } from '../../../context/AppModalsContext'
import { modalSteps } from '../../../utils/constants/app.constants'
import CircularProgress from '../../feedback/CircularProgress'

const ModalCircularProgressHeader = ({ title }) => {
  const { currentStep, stepsPercentage } = useAppModalsContext()

  return (
    <div className='flex items-center gap-2'>
      <CircularProgress
        size={36}
        radius={17}
        percentage={stepsPercentage}
        text={
          <>
            <tspan className='font-bold text-sm'>{currentStep}</tspan>
            <tspan className='text-xs fill-secondary'>/{modalSteps}</tspan>
          </>
        }
      />

      <h3 className='font-bold'>{title}</h3>
    </div>
  )
}

export default ModalCircularProgressHeader
