const TabPanel = ({ value, index, id, children, ...props }) => {
  return (
    <div
      role='tabpanel'
      style={{ display: value !== index && 'none' }}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...props}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

export const tabProps = (index, id) => {
  return {
    id: `${id}-tab-${index}`,
    'aria-controls': `${id}-tabpanel-${index}`,
  }
}

export default TabPanel
