import classNames from 'classnames'
import propTypes from 'prop-types'
import styles from './CardActions.module.css'

const CardActions = ({ children, className }) => {
  return <div className={classNames(styles.root, className)}>{children}</div>
}

CardActions.defaultProps = {
  className: '',
}

CardActions.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
}

export default CardActions
