import { api } from './api.service'
import { API } from '../utils/constants/app.constants'

export const signin = ({ email, password }) => {
  return api
    .post(API.signin, { email, password })
    .then((response) => response.data)
}

export const signup = ({
  first_name,
  last_name,
  phone_number,
  email,
  password,
  password_confirmation,
}) => {
  return api
    .post(API.signup, {
      first_name,
      last_name,
      phone_number,
      email,
      password,
      password_confirmation,
    })
    .then((response) => response.data)
}

export const getUser = () => {
  return api.get(API.user).then((response) => response.data)
}

export const updateUser = (data) => {
  const formData = new FormData()
  data.profile_picture && formData.append('avatar', data.profile_picture)
  data.first_name && formData.append('first_name', data.first_name)
  data.last_name && formData.append('last_name', data.last_name)
  data.email && formData.append('email', data.email)
  data.current_password &&
    formData.append('current_password', data.current_password)
  data.password && formData.append('password', data.password)
  data.password_confirmation &&
    formData.append('password_confirmation', data.password_confirmation)
  data.delete_avatar && formData.append('delete_avatar', data.delete_avatar)

  return api
    .post(`${API.user}/update`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
}

export const resendEmailVerification = () => {
  return api
    .post('email/verification-notification')
    .then((response) => response.data)
}
