import classNames from 'classnames'
import useCopy from '../../../../hooks/functions/useCopy'
import { pathConstants } from '../../../../router/pathConstants'
import Button from '../../../inputs/Button'

const WorldShareLink = ({ worldId, color }) => {
  const { isCopied, copy } = useCopy()
  const worldLink = `${process.env.REACT_APP_FRONTEND_URL}${pathConstants.BUILDER}/${worldId}`

  return (
    <div>
      <label htmlFor='share-link' className='w-full'>
        <span
          className={classNames('text-xs', {
            'text-lightGray': color !== 'white',
            'text-secondary': color === 'white',
          })}
        >
          Share file link
        </span>
      </label>
      <div className='flex h-10 gap-2 items-center mt-1'>
        <input
          id='share-link'
          type='text'
          className={classNames(
            'rounded-md w-full h-full px-4 text-sm bg-opacity-10 placeholder:text-secondary placeholder:text-xs',
            {
              'bg-secondary': color !== 'white',
              'bg-neutral-100 ring-neutral-100 border-neutral-100 focus:ring-neutral-100 focus:border-neutral-100 bg-opacity-100':
                color === 'white',
            }
          )}
          placeholder='Share link'
          readOnly
          value={worldLink}
        />

        <Button
          color={color === 'white' ? 'primary' : 'white'}
          radius={'md'}
          noPadding
          className='w-1/3 shrink-0 h-full px-4 py-2'
          onClick={() => copy(worldLink)}
        >
          {isCopied ? 'Copied' : 'Copy'}
        </Button>
      </div>
    </div>
  )
}

export default WorldShareLink
