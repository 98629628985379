import { HiCube, HiHome, HiSquares2X2, HiUserGroup } from 'react-icons/hi2'
import {
  IoIosSettings,
  IoMdArrowForward,
  IoMdInformationCircle,
} from 'react-icons/io'
import { pathConstants } from '../router/pathConstants'
import { FaBolt, FaGraduationCap } from 'react-icons/fa6'

export const topNavList = [
  {
    to: pathConstants.HOME,
    navItemText: 'Home',
    startIcon: <HiHome />,
  },
  {
    to: pathConstants.MY_WORLDS,
    navItemText: 'Worlds',
    startIcon: <HiCube />,
    dropdown: true,
    children: [
      {
        to: pathConstants.MY_WORLDS,
        navItemText: 'My worlds',
        isChild: true,
      },
      {
        to: pathConstants.SHARED_WORLDS,
        navItemText: 'Shared worlds',
        isChild: true,
      },
    ],
  },
  {
    to: pathConstants.TEMPLATES,
    navItemText: 'Templates',
    startIcon: <HiSquares2X2 />,
  },
  {
    to: pathConstants.LEARNING,
    navItemText: 'Learning',
    startIcon: <FaGraduationCap />,
  },
  {
    to: pathConstants.HELP_CENTER,
    navItemText: 'Help',
    startIcon: <IoMdInformationCircle />,
    dropdown: true,
    children: [
      {
        to: pathConstants.HELP_CENTER,
        navItemText: 'Help center',
        isChild: true,
      },
      {
        to: pathConstants.EMAIL_SUPPORT,
        navItemText: 'Email support',
        isChild: true,
      },
    ],
  },
]

export const bottomNavList = [
  {
    to: pathConstants.SUBSCRIPTION_PLANS,
    navItemText: 'Subscription plans',
    startIcon: <FaBolt />,
  },
  {
    to: pathConstants.SETTINGS,
    navItemText: 'Settings',
    startIcon: <IoIosSettings />,
  },
  {
    to: pathConstants.COMMUNITY,
    navItemText: 'Community',
    startIcon: <HiUserGroup />,
    endIcon: <IoMdArrowForward />,
  },
]
