import Cookies from 'js-cookie'

class CookieService {
  getCookie(name) {
    return Cookies.get(name)
  }

  setCookie(name, value, options) {
    //if the value is an object, stringify it
    let cookieValue = value
    if (typeof value === 'object') {
      cookieValue = JSON.stringify(value)
    }

    Cookies.set(name, cookieValue, options)
  }

  removeCookie(name) {
    return Cookies.remove(name)
  }
}

const cookieService = new CookieService()
export default cookieService
