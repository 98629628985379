import styles from './CircularIOSLoader.module.css'
import propTypes from 'prop-types'
import classNames from 'classnames'

const CircularIOSLoader = ({ color, width }) => (
  <div
    className={classNames(styles.loader, styles[color])}
    style={{ width: `${width}px` }}
  ></div>
)

CircularIOSLoader.defaultProps = {
  color: 'white',
  width: 24,
}

CircularIOSLoader.propTypes = {
  color: propTypes.string,
  width: propTypes.number,
}
export default CircularIOSLoader
