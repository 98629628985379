import { useRef, useState } from 'react'
import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import UnderlinedTabs from '../../utils/Tabs/UnderlinedTabs'
import UnderlinedTab from '../../utils/Tabs/UnderlinedTabs/UnderlinedTab'
import useCollaboratorsQuery from '../../../hooks/queries/useCollaboratorsQuery'
import VisitorsTab from './VisitorsTab'
import CollaboratorsTab from './CollaboratorsTab'
import { useBuilderModalsContext } from '../../../context/BuilderModalsContext'

const WorldShareModal = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const modalBodyRef = useRef(null)

  const { selectedWorld, shareModalColor: color } = useBuilderModalsContext()

  const onChangeTab = (e, newValue) => setSelectedTab(newValue)

  //get metaverse collaborators
  const { data: collaborators, isLoading: isLoadingCollaborators } =
    useCollaboratorsQuery({
      metaverseId: selectedWorld?.id,
      enabled: !!selectedWorld,
      refetchOnWindowFocus: false,
    })

  const visitorsList = collaborators?.data?.collaborators?.filter(
    (collaborator) => collaborator.role === 'viewer'
  )
  const collaboratorsList = collaborators?.data?.collaborators?.filter(
    (collaborator) => collaborator.role === 'editor'
  )

  return (
    <Modal open={open} onClose={onClose} size='lg' color={color}>
      <ModalHeader onClose={onClose} title='Share' />
      <ModalBody ref={modalBodyRef}>
        {open && (
          <>
            <UnderlinedTabs
              activeClass={color === 'white' ? '!text-primary' : '!text-accent'}
              inactiveClass={
                color === 'white' ? '!text-secondary' : '!text-lightGray'
              }
              dashClass={
                color === 'white' ? 'before:bg-primary' : 'before:bg-accent'
              }
              containerClasses={
                color === 'white' ? '!border-neutral-100' : '!border-secondary'
              }
              value={selectedTab}
              onChange={onChangeTab}
            >
              <UnderlinedTab label={'Visitor'} />
              <UnderlinedTab label={'Collaborator'} />
            </UnderlinedTabs>

            {/* visitors */}
            <VisitorsTab
              selectedTab={selectedTab}
              isLoading={isLoadingCollaborators}
              usersList={visitorsList}
              scrollableContainerRef={modalBodyRef}
            />

            {/* collaborators */}
            <CollaboratorsTab
              selectedTab={selectedTab}
              isLoading={isLoadingCollaborators}
              usersList={collaboratorsList}
              scrollableContainerRef={modalBodyRef}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

export default WorldShareModal
