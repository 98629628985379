import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import PropTypes from 'prop-types'
import Button from '../../inputs/Button'

const ConfirmDialogModal = ({
  open,
  onClose,
  title,
  text,
  confirmText,
  declineText,
  onConfirm,
  onDecline,
}) => {
  const onConfirmClick = () => {
    onConfirm()
    onClose()
  }

  const onDeclineClick = () => {
    if (onDecline) {
      onDecline()
    }
    onClose()
  }

  return (
    <>
      <Modal open={open} size='lg' onClose={onClose}>
        <ModalHeader open={open} onClose={onClose} title={title} />
        <ModalBody>
          <p className='text-lg'>{text}</p>

          <Button onClick={onConfirmClick} className='mt-4'>
            {confirmText}
          </Button>

          <Button onClick={onDeclineClick} className='mt-2' variant='outlined'>
            {declineText}
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

ConfirmDialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
}

ConfirmDialogModal.defaultProps = {
  declineText: 'Cancel',
  confirmText: 'Confirm',
}

export default ConfirmDialogModal
