import { ErrorMessage, Field, Form, Formik } from 'formik'
import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import * as Yup from 'yup'
import classNames from 'classnames'
import { IoMdInformationCircle } from 'react-icons/io'
import Button from '../../inputs/Button'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../../utils/helpers/error.helper'
import { pathConstants } from '../../../router/pathConstants'
import useMutateMetaverse from '../../../hooks/queries/useMutateMetaverse'
import ModalCircularProgressHeader from '../../related/ModalCircularProgressHeader'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const WorldConfigurationModal = ({ open, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const {
    modals: {
      [MODALS_NAMES.WORLD_CONFIGURATION]: { isStep, selectedWorld: metaverse },
    },
    handleModalToggle,
    setCurrentStep,
    modalsColor: color,
  } = useAppModalsContext()

  const onBackClick = () => {
    setCurrentStep((prev) => prev - 1)
    onClose()
    handleModalToggle(MODALS_NAMES.LIBRARY)
  }

  //mutation
  const { mutate, isLoading } = useMutateMetaverse({
    isNew: !metaverse,
    metaverse,
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
    },
    onCreateSuccess: (newMetaverse) => {
      //navigate to new metaverse
      navigate(`${pathConstants.BUILDER}/${newMetaverse.metaverse.id}`)
      onClose()
    },
    onUpdateSuccess: (newMetaverse) => {
      enqueueSnackbar(newMetaverse.message, { variant: 'success' })
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='md'
      color={color}
      className={'faded-container'}
    >
      <ModalHeader
        onClose={onClose}
        hasBack={isStep}
        onBackClick={onBackClick}
        title={
          isStep ? (
            <ModalCircularProgressHeader
              title={'Create title and description'}
            />
          ) : (
            'Create title and description'
          )
        }
      />
      <ModalBody>
        {open && (
          <Formik
            initialValues={{
              old_name: metaverse ? metaverse.name : '',
              name: metaverse ? metaverse.name : '',
              old_description: metaverse ? metaverse.description ?? '' : '',
              description: metaverse ? metaverse.description ?? '' : '',
              template_id: props?.templateId,
            }}
            // enableReinitialize={true}
            validationSchema={Yup.object({
              name: Yup.string()
                .required('Required')
                //check if the name is the same as the old name
                .test(
                  'is-same',
                  'Name is the same as the old name',
                  function (value) {
                    if (!value) {
                      return true
                    }
                    return value !== this.parent.old_name
                  }
                ),
              description: Yup.string()
                //check if the description is the same as the old description
                .test(
                  'is-same',
                  'Description is the same as the old description',
                  function (value) {
                    if (!value) {
                      return true
                    }
                    return value !== this.parent.old_description
                  }
                ),
            })}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm }) => {
              mutate(values, {
                onSuccess: () => {
                  setSubmitting(false)
                  if (!metaverse) {
                    resetForm()
                  }
                },
                onError: () => {
                  setSubmitting(false)
                },
              })
            }}
          >
            {(formik) => (
              <Form className='flex flex-col gap-4'>
                <div className='flex flex-col gap-2'>
                  <label
                    className={classNames('text-sm text-primary-light', {
                      'text-white': color === 'dark',
                    })}
                    htmlFor='name'
                  >
                    World title
                  </label>
                  <Field
                    name='name'
                    id='name'
                    placeholder={'Title'}
                    className={classNames(
                      'w-full rounded-lg bg-neutral-100 focus:ring-0 border-none placeholder:text-sm py-2 px-3',
                      {
                        'bg-primary-light ring-primary-light': color === 'dark',
                      }
                    )}
                  />
                  <ErrorMessage
                    name='name'
                    className='pl-2 text-sm text-red-700'
                    component={'div'}
                  />
                </div>

                <div className='flex flex-col gap-2'>
                  <label
                    className={classNames('text-sm text-primary-light', {
                      'text-white': color === 'dark',
                    })}
                    htmlFor='description'
                  >
                    World description
                  </label>
                  <Field
                    as='textarea'
                    name='description'
                    id='description'
                    placeholder={'Write about your world...'}
                    className={classNames(
                      'w-full bg-neutral-100 rounded-lg focus:ring-0 border-none placeholder:text-sm py-2 px-3',
                      {
                        'bg-primary-light ring-primary-light': color === 'dark',
                      }
                    )}
                    rows={4}
                  />
                  <ErrorMessage
                    name='description'
                    className='pl-2 text-sm text-red-700'
                    component={'div'}
                  />
                </div>

                <div
                  className={classNames(
                    'flex items-center bg-neutral-100 gap-2 rounded-2xl py-2 px-3 text-sm min-h-12',
                    {
                      'bg-primary-light': color === 'dark',
                    }
                  )}
                >
                  <IoMdInformationCircle className='text-xl' />
                  <span>you can create title and description at any time</span>
                </div>

                <div className='flex gap-4 items-center'>
                  <Button
                    type='submit'
                    color={color === 'dark' ? 'white' : 'primary-light'}
                    size={'sm'}
                    fullWidth
                    disabled={formik.isSubmitting || isLoading}
                  >
                    Confirm
                  </Button>
                  <Button
                    type='button'
                    className={classNames({
                      'ring-1 ring-white': color === 'dark',
                    })}
                    size={'sm'}
                    fullWidth
                    onClick={onClose}
                    variant={color === 'dark' ? 'filled' : 'outlined'}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  )
}

export default WorldConfigurationModal
