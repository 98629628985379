import TextField from '../../inputs/TextField'
import { useState } from 'react'
import styles from './Header.module.css'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { IoMdClose, IoMdMenu } from 'react-icons/io'
import { HiBell } from 'react-icons/hi2'
import { IoSearchOutline } from 'react-icons/io5'
import { pathConstants } from '../../../router/pathConstants'
import Avatar from '../../utils/Avatar'
import DashboardNotifications from '../../related/DashboardNotifications'
import { useAuth } from '../../../context/AuthContext'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../../utils/constants/query.constants'
import { getAllNotifications } from '../../../services/notifications.service'
import { useHomeContext } from '../../../context/HomeContext'

const Header = ({ showSidebar, setShowSidebar }) => {
  const [openSearch, setOpenSearch] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)

  const { user } = useAuth()
  const { headerTitle } = useHomeContext()
  const navigate = useNavigate()

  const handleToggleNotifications = () => setOpenNotifications((prev) => !prev)

  //get notifications
  const { data: notificationsData, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.notifications],
    queryFn: getAllNotifications,
    refetchOnWindowFocus: false,
    refetchInterval: 60000,
  })

  return (
    <header className={styles.base}>
      <div className={styles.inner}>
        <div className={styles.titleContainer}>
          <button
            onClick={() => setShowSidebar(!showSidebar)}
            className='md:hidden'
          >
            <IoMdMenu className='w-6 h-6' />
          </button>
          {typeof headerTitle === 'string' ? (
            <h1 className={styles.title}>{headerTitle}</h1>
          ) : (
            headerTitle
          )}
        </div>
        <div className={styles.right}>
          <div
            className={classNames(styles.search, {
              [styles.close]: !openSearch,
            })}
          >
            <TextField
              placeholder='Search for worlds, templates'
              startAdornment={
                <IoSearchOutline className='text-xl text-secondary' />
              }
              containerProps={{
                className: styles.searchBar,
              }}
              inputProps={{
                className: styles.searchInput,
              }}
            />
            <button onClick={() => setOpenSearch(false)} className='md:hidden'>
              <IoMdClose className='h-8 w-8' />
            </button>
          </div>

          <div
            className={classNames(styles.iconBox, 'md:!hidden')}
            onClick={() => setOpenSearch(true)}
          >
            <IoSearchOutline />
          </div>
          <div className='relative'>
            <div
              role='button'
              className={classNames(styles.iconBox, {
                [styles.active]: openNotifications,
              })}
              onClick={handleToggleNotifications}
            >
              <HiBell />
            </div>
            {notificationsData?.unread_count > 0 && (
              <span className={styles.notificationCount}>
                {notificationsData.unread_count}
              </span>
            )}
          </div>
          <DashboardNotifications
            open={openNotifications}
            toggle={handleToggleNotifications}
            notificationsData={notificationsData}
            isLoading={isLoading}
          />
          <Avatar
            role='button'
            src={user?.avatar}
            alt={user?.name}
            className={styles.avatar}
            onClick={() => navigate(pathConstants.ACCOUNT)}
          />
        </div>
      </div>
    </header>
  )
}

export default Header
