import { Outlet, Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from '../context/AuthContext'
import { pathConstants } from './pathConstants'
import cookieService from '../services/cookie.service'
import { cookiesNames } from '../utils/constants/cookies.constant'
import LoadingScreen from '../screens/LoadingScreen'

const ProtectedRoute = ({ allowedRoles }) => {
  const { user, isLoading: isLoadingUser } = useAuth()
  const location = useLocation()

  const isLoading =
    isLoadingUser ||
    (cookieService.getCookie(cookiesNames.ACCESS_TOKEN) && !user)

  if (isLoading) {
    // If still loading, you can render a loading indicator or return null
    return <LoadingScreen />
  }

  if (!user || !user.roles.find((role) => allowedRoles?.includes(role))) {
    // If not authenticated or user doesn't have the required roles, redirect to '/signin'
    return (
      <Navigate replace to={pathConstants.SIGNIN} state={{ from: location }} />
    )
  }

  // If authenticated and has the required roles, render the child routes
  return <Outlet />
}

ProtectedRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
}

export default ProtectedRoute
