// react-query keys
export const QUERY_KEYS = {
  user: 'user',
  templates: 'templates',
  metaverses: 'metaverses',
  profile: 'profile',
  collaborators: 'collaborators',
  sharedMetaverses: 'sharedMetaverses',
  settings: 'settings',
  invitations: 'invitations',
  notifications: 'notifications',
  dashboardSettings: 'dashboardSettings',
  settingsPerMetaverse: 'settingsPerMetaverse',
  languages: 'languages',
}
