import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import CircularLoader from '../components/feedback/CircularLoader'

const BuilderLayout = () => {
  return (
    <div className='flex-1 h-full'>
      <Suspense
        fallback={
          <div className='flex w-full justify-center items-center'>
            <CircularLoader />
          </div>
        }
      >
        <div className='h-screen w-screen relative' style={{zIndex:1}}>
          <Outlet />
        </div>
      </Suspense>
    </div>
  )
}

export default BuilderLayout
