import CircularLoader from '../components/feedback/CircularLoader'

const LoadingScreen = ({ label }) => {
  return (
    <div className='flex flex-col h-full w-full justify-center items-center bg-white'>
      <CircularLoader />
      {label && (
        <p className='text-sm text-center font-medium mt-12'>{label}</p>
      )}
    </div>
  )
}

export default LoadingScreen
