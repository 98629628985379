import { useAuth } from '../context/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
import { pathConstants } from './pathConstants'

const GuestRoute = () => {
  const { user } = useAuth()
  return user ? <Navigate to={pathConstants.HOME} /> : <Outlet />
}

export default GuestRoute
