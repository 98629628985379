import { cloneElement } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Tabs.module.css'

const Tabs = ({
  children,
  value,
  onChange,
  direction,
  rounded,
  tabClassName,
  tabColor,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        [styles.direction],
        [rounded],
        tabClassName
      )}
    >
      {children.map((child, index) => {
        return cloneElement(child, {
          key: index,
          'aria-selected': index === value,
          tabIndex: index === value ? 0 : -1,
          variant: index === value ? 'filled' : 'text',
          color: index === value ? tabColor.selected : tabColor.unselected,
          onClick: (e) => onChange(e, index),
          ...props,
        })
      })}
    </div>
  )
}

Tabs.propType = {
  children: propTypes.node.isRequired,
  value: propTypes.number.isRequired,
  onChange: propTypes.func.isRequired,
  direction: propTypes.oneOf(['horizontal', 'vertical']),
  rounded: propTypes.oneOf(['none', 'sm', 'md', 'lg', 'full']),
  tabColor: propTypes.object,
}

Tabs.defaultProps = {
  direction: 'horizontal',
  rounded: 'none',
  tabColor: {
    selected: 'primary-light',
    unselected: 'secondary',
  },
}

export default Tabs
