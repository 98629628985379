import styles from './CircularProgress.module.css'

const CircularProgress = ({
  percentage,
  size,
  radius,
  strokeWidth,
  text,
  anchorTransform,
}) => {
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * percentage) / 100

  return (
    <div>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          className='fill-none stroke-slate-100'
        />

        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          className={styles.progress}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          //   transform={`rotate(-90 ${size / 2} ${size / 2})`} //to start from top of the circle
          transform={anchorTransform}
        />

        <text x={'50%'} y={'50%'} dy={'0.3em'} textAnchor='middle' className=''>
          {text}
        </text>
      </svg>
    </div>
  )
}

CircularProgress.defaultProps = {
  radius: 17,
  strokeWidth: '2px',
}

export default CircularProgress
