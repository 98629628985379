import axios from 'axios'
import cookieService from './cookie.service'
import { BASEURL, guestRoutes } from '../utils/constants/app.constants'
import { cookiesNames } from '../utils/constants/cookies.constant'
import { pathConstants } from '../router/pathConstants'

export let api
export let source

export const createApiInstance = () => {
  source = axios.CancelToken.source()

  api = axios.create({
    baseURL: BASEURL,
    headers: {
      'Content-Type': 'application/json',
    },
    cancelToken: source.token,
  })
}

export const cancelAllRequests = () => {
  source.cancel('Operation canceled by the user.')
  createApiInstance()
}

createApiInstance()

export const setupInterceptors = (navigate, location, user, logout) => {
  //request
  api.interceptors.request.use(
    (config) => {
      const token = cookieService.getCookie(cookiesNames.ACCESS_TOKEN)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  //response
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      //handle connection error
      if (!error.response) {
        return Promise.reject(error)
      }
      if (error.response.status === 401 && user) {
        logout()
      }
      if (
        error.response.status === 403 &&
        error.response?.data?.message?.includes('not verified')
      ) {
        if (
          !guestRoutes.includes(location.pathname) &&
          location.pathname !== pathConstants.EMAIL_VERIFY
        ) {
          navigate(pathConstants.EMAIL_VERIFY, {
            replace: true,
            state: { from: location.state?.from || location.state },
          })
        }
      }
      return Promise.reject(error)
    }
  )
}
