import { HiMiniPlus } from 'react-icons/hi2'
import { useBuilderModalsContext } from '../../../../context/BuilderModalsContext'
import Button from '../../../inputs/Button'
import { QUERY_KEYS } from '../../../../utils/constants/query.constants'
import {
  addAdditionalLanguage,
  getAvailableLanguages,
  getMetaverseLanguages,
} from '../../../../services/languages.service'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getErrorMessage } from '../../../../utils/helpers/error.helper'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import useSettingPerMetaverseMutation from '../../../../hooks/queries/useSettingPerMetaverseMutation'
import useValidateQuery from '../../../../hooks/queries/useValidateQuery'
import builderScreenTranslations from '../../../../locals/builderScreenTranslations.json'

const LanguageConfiguration = ({ findPivotByName }) => {
  const [additionalLanguage, setAdditionalLanguage] = useState('')
  const {
    selectedWorld,
    openBuilderSettingModal: open,
    worldDefaultLanguage,
  } = useBuilderModalsContext()
  const { enqueueSnackbar } = useSnackbar()
  const { validateQuery } = useValidateQuery()

  //queries

  //get system languages
  const { data: availableLanguages, isLoading: isLoadingAvailableLanguages } =
    useQuery({
      queryKey: [QUERY_KEYS.languages],
      queryFn: getAvailableLanguages,
      onError: (error) => {
        console.log(
          '[BuilderSettingModal] getAvailableLanguages error',
          getErrorMessage(error)
        )
      },
      enabled: !!selectedWorld && open,
      refetchOnWindowFocus: false,
    })

  //get metaverse languages
  const {
    data: metaverseLanguages,
    isLoading: isLoadingMetaverseLanguages,
    refetch: refetchMetaverseLanguages,
  } = useQuery({
    queryKey: [QUERY_KEYS.languages, selectedWorld?.id],
    queryFn: () => getMetaverseLanguages(selectedWorld?.id),
    onError: (error) => {
      console.log(
        '[BuilderSettingModal] getMetaverseLanguages error',
        getErrorMessage(error)
      )
    },
    enabled: !!selectedWorld && open,
    refetchOnWindowFocus: false,
  })

  const findLanguageInMetaverseLanguages = (languageCode) => {
    return metaverseLanguages?.languages?.find(
      (lang) => lang.code === languageCode
    )
  }

  //mutations -- add additional language
  const {
    mutate: mutateAdditionalLanguage,
    isLoading: isLoadingAdditionalLanguage,
  } = useMutation({
    mutationKey: ['addAdditionalLanguage', selectedWorld?.id],
    mutationFn: (data) =>
      addAdditionalLanguage(selectedWorld?.id, data.languageId),
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      })
    },
    onSuccess: () => {
      refetchMetaverseLanguages()
      validateQuery([QUERY_KEYS.metaverses, selectedWorld?.id.toString()])
      enqueueSnackbar('Language added successfully', {
        variant: 'success',
      })
    },
  })

  //update other settings
  const { mutate: updateSetting, isLoading: isUpdating } =
    useSettingPerMetaverseMutation({
      metaverseId: selectedWorld?.id,
      onSuccess: () => {
        enqueueSnackbar('Settings updated successfully', {
          variant: 'success',
        })
      },
      appendError: (error) => {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
        })
      },
    })

  const submitAdditionalLanguage = () => {
    //find language id
    const language = availableLanguages?.languages.find(
      (lang) => lang.code === additionalLanguage
    )
    mutateAdditionalLanguage({
      languageId: language.id,
    })
  }

  useEffect(() => {
    if (availableLanguages?.languages?.length > 0) {
      setAdditionalLanguage(availableLanguages?.languages[0].code)
    }
  }, [availableLanguages])

  return (
    <div className='top-faded-container rounded-lg p-2 mb-5'>
      <h2 className='text-[15px] font-medium text-white mb-2'>
        {
          builderScreenTranslations[worldDefaultLanguage?.code]
            .builder_settings_modal.language_section.title
        }
      </h2>

      <label htmlFor='languages' className='block mb-2 text-[12px] text-white'>
        {
          builderScreenTranslations[worldDefaultLanguage?.code]
            .builder_settings_modal.language_section.default_language_label
        }
      </label>

      <select
        id='languages'
        className='bg-primary-light  text-white text-sm rounded-lg block w-full p-2.5 mb-4'
        defaultValue={findPivotByName('default_language')?.value || 'en'}
        onChange={(e) => {
          updateSetting({
            metaverse_setting_id: findPivotByName('default_language')?.id,
            value: e.target.value,
          })
        }}
        disabled={isUpdating || isLoadingMetaverseLanguages}
      >
        {metaverseLanguages?.languages?.map((language) => (
          <option key={language.id} value={language.code}>
            {language.name}
          </option>
        ))}
      </select>

      <label htmlFor='languages' className='block mb-2 text-[12px] text-white'>
        {
          builderScreenTranslations[worldDefaultLanguage?.code]
            .builder_settings_modal.language_section.additional_languages_label
        }
      </label>

      <select
        id='languages'
        className='bg-primary-light  text-white text-sm rounded-lg block w-full p-2.5'
        value={additionalLanguage}
        onChange={(e) => setAdditionalLanguage(e.target.value)}
        disabled={isLoadingAvailableLanguages || isLoadingMetaverseLanguages}
      >
        {availableLanguages?.languages.map((language) => (
          <option
            key={language.id}
            value={language.code}
            disabled={findLanguageInMetaverseLanguages(language.code)}
          >
            {language.name}
          </option>
        ))}
      </select>

      <Button
        fullWidth
        color='primary'
        className='mt-2 border-2 border-dashed border-accent'
        onClick={submitAdditionalLanguage}
        disabled={
          isLoadingAdditionalLanguage ||
          findLanguageInMetaverseLanguages(additionalLanguage)
        }
      >
        <span>
          {
            builderScreenTranslations[worldDefaultLanguage?.code]
              .builder_settings_modal.language_section
              .additional_languages_button
          }
        </span>
        <HiMiniPlus />
      </Button>
    </div>
  )
}

export default LanguageConfiguration
