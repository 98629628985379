import { HiFolder, HiOutlineEllipsisVertical, HiTrash } from 'react-icons/hi2'
import { IoMdArrowForward, IoMdInformationCircle } from 'react-icons/io'
import styles from './WorldCard.module.css'
import Button from '../../inputs/Button'
import Card from '../../utils/Card'
import CardMedia from './../../utils/Card/CardMedia'
import CardContent from './../../utils/Card/CardContent'
import CardActions from './../../utils/Card/CardActions'
import { useEffect, useRef, useState } from 'react'
import Menu from '../../utils/Menu'
import MenuItem from '../../utils/Menu/MenuItem'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Carousel from '../../utils/Carousel'
import { images } from '../../../data/images'
import useDeleteMetaverse from '../../../hooks/queries/useDeleteMetaverse'
import ConfirmDialogModal from '../../modals/ConfirmDialogModal'
import { useNavigate } from 'react-router-dom'
import { pathConstants } from '../../../router/pathConstants'
import { useBuilderModalsContext } from '../../../context/BuilderModalsContext'

const WorldCard = ({
  world,
  mediaCarousel,
  className,
  dropdownProps,
  carouselMenu,
  actionText,
  onClick,
  showDescription,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const [menuDirection, setMenuDirection] = useState('left')
  const [dialogDetails, setDialogDetails] = useState({ open: false })
  const card = useRef(null)
  const menu = useRef(null)

  const navigate = useNavigate()
  const { setShareModalColor, handleOpenShareModal, setSelectedWorld } =
    useBuilderModalsContext()

  const handleMenuClick = () => {
    setShowMenu((prev) => !prev)
  }

  const onConfirmDialog = () => {
    setDialogDetails({ open: false })
    deleteMetaverseMutation()
  }

  //delete metaverse mutation
  const { mutate: deleteMetaverseMutation, isLoading: isDeleting } =
    useDeleteMetaverse({
      id: world?.id,
    })

  //close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menu.current && !menu.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [card])

  //calculate menu direction based on the card position and the window width
  useEffect(() => {
    const getDirection = () => {
      const { innerWidth } = window
      const { right } = card?.current?.getBoundingClientRect()

      //if the card is close to the right edge of the screen, start the menu from the right
      if (innerWidth - right < 200) {
        setMenuDirection('right')
      } else {
        //otherwise, start the menu from the left
        setMenuDirection('left')
      }
    }

    getDirection()

    //recalculate the menu direction on window resize
    window.addEventListener('resize', getDirection)

    return () => {
      window.removeEventListener('resize', getDirection)
    }
  }, [])

  return (
    <>
      <Card className={classNames(styles.root, className)} ref={card}>
        {mediaCarousel ? (
          <CardMedia className={styles.cardMedia}>
            <Carousel
              itemsGap={0}
              showHeader={false}
              controlsPosition='center'
              controlsDistance='between'
              roundedControls
              showIndicators
              controlsColor={'white'}
              controlsSize={'xs'}
              className='h-full'
            >
              {images.map((image, index) => (
                <img src={image} alt={index} key={index} />
              ))}
            </Carousel>
          </CardMedia>
        ) : (
          <CardMedia
            className={styles.cardMedia}
            image={world.thumbnail || 'https://placehold.co/600x400'}
            alt={world.name}
          />
        )}

        <CardContent>
          <div className={styles.cardHeader}>
            <h5 className={styles.cardTitle}>{world.name}</h5>
            <div
              className={classNames(
                'dropdown relative',
                dropdownProps?.className
              )}
            >
              <button onClick={handleMenuClick}>
                <HiOutlineEllipsisVertical className={styles.menuIcon} />
              </button>
              {showMenu && (
                <Menu
                  ref={menu}
                  direction={menuDirection}
                  anchorOrigin={`top-${menuDirection}`}
                  className={classNames(
                    styles.dropdownMenu,
                    {
                      '!left-[-10px]': menuDirection === 'left',
                      'bottom-0': carouselMenu,
                    },
                    'mt-2'
                  )}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(`${pathConstants.WORLD_ANALYTICS}/${world.id}`)
                      handleMenuClick()
                    }}
                  >
                    <IoMdInformationCircle />
                    <span>Analytics</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      console.log(world)
                      setShareModalColor('white')
                      setSelectedWorld(world)
                      handleOpenShareModal()
                    }}
                  >
                    <IoMdArrowForward />
                    <span>Share</span>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClick}>
                    <HiFolder />
                    <span>Archive</span>
                  </MenuItem>
                  <MenuItem
                    disabled={isDeleting}
                    onClick={() => {
                      setDialogDetails({
                        open: true,
                        title: 'Delete world',
                        text: 'Are you sure you want to delete this world?',
                      })
                    }}
                  >
                    <HiTrash />
                    <span>Delete</span>
                  </MenuItem>
                </Menu>
              )}
            </div>
          </div>

          {showDescription && world?.description && (
            <p className={styles.cardDescription}>{world.description}</p>
          )}
        </CardContent>

        <CardActions>
          <Button fullWidth className={styles.cardBtn} onClick={onClick}>
            <span>{actionText}</span>
            <IoMdArrowForward />
          </Button>
        </CardActions>
      </Card>

      <ConfirmDialogModal
        open={dialogDetails.open}
        onClose={() => setDialogDetails({ open: false })}
        title={dialogDetails.title || 'Delete world'}
        text={
          dialogDetails.text || 'Are you sure you want to delete this world?'
        }
        onConfirm={onConfirmDialog}
      />
    </>
  )
}

WorldCard.propTypes = {
  dropdownProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  world: PropTypes.shape({
    thumbnail: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  carouselMenu: PropTypes.bool,
  mediaCarousel: PropTypes.bool,
  actionText: PropTypes.string,
  showDescription: PropTypes.bool,
}

WorldCard.defaultProps = {
  carouselMenu: false,
  mediaCarousel: false,
  actionText: 'Enter world',
  showDescription: false,
}

export default WorldCard
