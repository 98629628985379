import classNames from 'classnames'
import Button from '../../../inputs/Button'
import styles from './Tab.module.css'

const Tab = ({ label, icon, ...props }) => {
  return (
    <Button
      type='button'
      role='tab'
      fullWidth
      className={classNames(styles.root, props?.className)}
      {...props}
    >
      {icon && icon}
      {label}
    </Button>
  )
}

export default Tab
