import { IoIosSettings, IoMdClose } from 'react-icons/io'
import { IoCheckmarkDone } from 'react-icons/io5'
import Button from '../../inputs/Button'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../../utils/constants/query.constants'
import { markAllNotificationsAsRead } from '../../../services/notifications.service'
import CircularIOSLoader from '../../feedback/CircularIOSLoader'
import DashboardNotificationItem from './DashboardNotificationItem'
import useValidateQuery from './../../../hooks/queries/useValidateQuery'

const DashboardNotifications = ({
  open,
  toggle,
  notificationsData,
  isLoading,
}) => {
  const innerRef = useRef()

  const notifications = notificationsData?.notifications
  const unreadCount = notificationsData?.unread_count

  const { validateQuery } = useValidateQuery()

  const { mutate, isLoading: isMarkingAllAsRead } = useMutation({
    mutationKey: ['markAllNotificationsAsRead'],
    mutationFn: markAllNotificationsAsRead,
    onSuccess: (_) => validateQuery([QUERY_KEYS.notifications]),
  })

  //close notification container when clicked outside
  useEffect(() => {
    const handleClick = (e) => {
      if (innerRef.current && !innerRef.current.contains(e.target) && open) {
        toggle()
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [innerRef, open, toggle])

  return (
    <div
      className={classNames(
        'absolute top-0 right-0 h-full w-full flex items-center justify-end bg-black bg-opacity-25 z-40 pt-16 pb-4 pr-7',
        {
          hidden: !open,
        }
      )}
    >
      <div
        className='flex flex-col h-full bg-white rounded-xl min-w-[40%] max-w-[70%]'
        ref={innerRef}
      >
        <div className='shrink-0 px-6 pt-6'>
          <div className='flex justify-between items-center'>
            <h3 className='text-base font-bold'>Notifications</h3>
            <IoMdClose
              className='text-xl text-primary cursor-pointer'
              onClick={toggle}
            />
          </div>
          <hr className='mt-4' />
        </div>
        <div className='flex-1 flex flex-col overflow-y-auto'>
          {isLoading ? (
            <div className='flex justify-center items-center h-40'>
              <CircularIOSLoader color={'black'} />
            </div>
          ) : notifications?.length ? (
            <>
              {notifications.map((notification, index) => (
                <div key={notification.id}>
                  <DashboardNotificationItem notification={notification} />

                  {index < notifications.length - 1 && (
                    <hr className='my-2 mx-6' />
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className='flex justify-center items-center h-40 py-6 px-4'>
              <p className='text-sm text-secondary text-center '>
                There is no notifications
              </p>
            </div>
          )}
        </div>
        <div className='shrink-0 px-6 pb-6'>
          <div className='flex justify-between items-center'>
            <div
              role='button'
              className='w-8 h-8 cursor-pointer flex justify-center items-center ring-1 ring-primary-light rounded-full overflow-hidden'
            >
              <IoIosSettings className='text-xl text-primary' />
            </div>
            <Button
              size={'sm'}
              radius={'lg'}
              className={'text-sm'}
              disabled={unreadCount === 0 || isMarkingAllAsRead}
              onClick={mutate}
            >
              <span className='text-inherit'>Mark as read</span>
              <IoCheckmarkDone className='text-lg' />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardNotifications
