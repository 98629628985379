import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../utils/constants/query.constants'
import {
  blockUser,
  unblockUser,
} from '../../services/metaverse-invites.service'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../utils/helpers/error.helper'

const useCollaboratorBlock = ({ metaverseId, isUnblock, ...rest }) => {
  const queryClient = useQueryClient()
  const metaverseIdString = metaverseId?.toString()

  const { enqueueSnackbar } = useSnackbar()

  return useMutation({
    mutationKey: [QUERY_KEYS.collaborators, metaverseIdString],
    mutationFn: (data) =>
      isUnblock
        ? unblockUser(metaverseId, data.inviteeId)
        : blockUser(metaverseId, data.inviteeId),
    onMutate: (variables) => {
      //optimistic update

      //cancel any outgoing refetches
      queryClient.cancelQueries([QUERY_KEYS.collaborators, metaverseIdString])

      //snapshot the previous value
      const previousCollaborators = queryClient.getQueryData([
        QUERY_KEYS.collaborators,
        metaverseIdString,
      ])

      //optimistically update to the new value
      queryClient.setQueryData(
        [QUERY_KEYS.collaborators, metaverseId.toString()],
        (old) => {
          return old
            ? {
                ...old,
                data: {
                  ...old.data,
                  //update the status of the collaborator to blocked/accepted
                  collaborators: old.data.collaborators.map((collaborator) => {
                    if (collaborator.id === variables.inviteeId) {
                      return {
                        ...collaborator,
                        status: isUnblock ? 'accepted' : 'blocked',
                      }
                    }
                    return collaborator
                  }),
                },
              }
            : old
        }
      )

      //return a context object with the snapshotted value
      return { previousCollaborators }
    },

    onError: (error, _, context) => {
      queryClient.setQueryData(
        [QUERY_KEYS.collaborators, metaverseIdString],
        context.previousCollaborators
      )

      enqueueSnackbar(getErrorMessage(error), { variant: 'error' })
    },
    onSuccess: (data) => {
      enqueueSnackbar(data.message, { variant: 'success' })
    },
    ...rest,
  })
}

export default useCollaboratorBlock
