import classNames from 'classnames'
import { useBuilderModalsContext } from '../../../../context/BuilderModalsContext'
import useInviteAutocomplete from '../../../../hooks/useInviteAutocomplete'
import CircularIOSLoader from '../../../feedback/CircularIOSLoader'
import Button from '../../../inputs/Button'
import Chip from '../../../utils/Chip'

const InviteAutocomplete = ({ hidden, selectedTab, setFeedback }) => {
  const { selectedWorld, shareModalColor: color } = useBuilderModalsContext()

  const {
    inputContainerRef,
    dropdownRef,
    isLoadingEmails,
    emailFieldValue,
    isNotValidEmail,
    selectedEmails,
    availableEmails,
    isInviting,
    mutate,
    handleChangeEmailValue,
    handleDeleteEmail,
    handleKeyDown,
    handleSelectEmail,
  } = useInviteAutocomplete({
    worldId: selectedWorld?.id,
    selectedTab,
    setFeedback,
  })

  return (
    <div
      className={classNames({
        hidden,
      })}
    >
      <label htmlFor='email' className='w-full'>
        <span
          className={classNames('text-xs', {
            'text-lightGray': color !== 'white',
            'text-secondary': color === 'white',
          })}
        >
          Invite with email
        </span>
      </label>
      <div className='flex min-h-10 gap-2 items-start mt-1'>
        <div className='relative flex-1'>
          <div
            ref={inputContainerRef}
            className={classNames(
              'flex flex-wrap w-full min-h-10 max-h-32 overflow-y-auto bg-opacity-10 ring-1  rounded-md gap-1',
              {
                'ring-red-700': isNotValidEmail && selectedTab === 0,
                'ring-secondary': !isNotValidEmail && color !== 'white',
                'ring-neutral-100': !isNotValidEmail && color === 'white',
                'bg-secondary': color !== 'white',
                'bg-neutral-100 bg-opacity-100': color === 'white',
              }
            )}
          >
            {selectedEmails.length > 0 && (
              <div className='flex items-center flex-wrap gap-2 pl-2 py-1'>
                {selectedEmails.map((email) => (
                  <Chip
                    key={email}
                    label={email}
                    variant={'filled'}
                    color={'primary'}
                    onDelete={() => handleDeleteEmail(email)}
                  />
                ))}
              </div>
            )}

            <input
              // ref={emailFieldRef}
              autoComplete='off'
              id='email'
              type='text'
              rows={1}
              className='flex-1 min-h-10 h-full px-2 text-sm bg-transparent border-0 outline-none focus:border-0 focus:outline-none focus:ring-0 placeholder:text-secondary placeholder:text-xs'
              placeholder='Email, comma separated'
              value={emailFieldValue}
              onChange={handleChangeEmailValue}
              onKeyDown={handleKeyDown}
            />
          </div>
          {isNotValidEmail && selectedTab === 0 && (
            <span className='ml-2 text-xs text-red-700'>Invalid email</span>
          )}

          {emailFieldValue !== '' && (
            <div
              ref={dropdownRef}
              className={classNames(
                'absolute top-12 left-0 right-0 z-50 max-h-52 overflow-y-auto  flex flex-col gap-1 rounded-lg p-2 bg-neutral-800',
                {
                  hidden:
                    !availableEmails.length &&
                    selectedTab === 0 &&
                    !isLoadingEmails,
                }
              )}
            >
              {isLoadingEmails ? (
                <div className='flex justify-center'>
                  <CircularIOSLoader />
                </div>
              ) : availableEmails.length > 0 ? (
                <>
                  {availableEmails.map((email) => (
                    <span
                      role='button'
                      className='text-sm text-lightGray cursor-pointer px-1 py-2'
                      key={email}
                      onClick={() => handleSelectEmail(email)}
                    >
                      {email}
                    </span>
                  ))}
                </>
              ) : (
                <span className='text-sm w-full text-center text-lightGray px-1 py-2'>
                  No results
                </span>
              )}
            </div>
          )}
        </div>

        <Button
          color={color === 'white' ? 'primary' : 'white'}
          radius={'md'}
          noPadding
          className='w-1/3 shrink-0 h-full px-4 py-2'
          onClick={() =>
            mutate({
              role: selectedTab === 0 ? 'viewer' : 'editor',
              emails: selectedEmails,
            })
          }
          disabled={isInviting || selectedEmails.length === 0}
        >
          {isInviting ? (
            <CircularIOSLoader color={color === 'white' ? 'white' : 'black'} />
          ) : (
            'Invite'
          )}
        </Button>
      </div>
    </div>
  )
}

InviteAutocomplete.defaultProps = {
  hidden: false,
  selectedTab: 0,
}

export default InviteAutocomplete
