import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import NewWorldOptionCard from '../../cards/NewWorldOptionCard'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import PropTypes from 'prop-types'
import ModalCircularProgressHeader from '../../related/ModalCircularProgressHeader'
import CardImage from '../../cards/NewWorldOptionCard/CardImage'
import backgroundImage from '../../../assets/images/backgrounds/avatar-option-card-bg.png'
import whiteLogo from '../../../assets/images/logo/white-logo.png'
import readyPlayerMeLogo from '../../../assets/images/logo/ready-player-me-logo.png'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const ChooseAvatarModal = ({ open, onClose }) => {
  const { handleModalToggle, setCurrentStep, handleChangeModalConfig } =
    useAppModalsContext()

  const onBackClick = () => {
    setCurrentStep((prev) => prev - 1)
    onClose()
    handleModalToggle(MODALS_NAMES.NEW_WORLD)
  }

  const handleClose = () => {
    setCurrentStep(1)
    onClose()
  }

  const cardOnClick = (card) => {
    switch (card) {
      case 'readyPlayerMe': {
        setCurrentStep((prev) => prev + 1)
        handleChangeModalConfig(MODALS_NAMES.LIBRARY, {
          type: 'readyPlayerMe',
        })
        onClose()
        handleModalToggle(MODALS_NAMES.LIBRARY)
        break
      }
      case 'avatars': {
        setCurrentStep((prev) => prev + 1)
        handleChangeModalConfig(MODALS_NAMES.LIBRARY, {
          type: 'avatars',
        })
        onClose()
        handleModalToggle(MODALS_NAMES.LIBRARY)
        break
      }
      default:
        break
    }
  }

  return (
    <>
      <Modal open={open} size='xl' onClose={handleClose}>
        <ModalHeader
          open={open}
          onClose={handleClose}
          hasBack
          onBackClick={onBackClick}
          title={<ModalCircularProgressHeader title={'Choose your avatar'} />}
        />
        <ModalBody>
          <div className='flex gap-4'>
            <NewWorldOptionCard
              actionText='Ready Player Me'
              image={
                <CardImage
                  icon={
                    <img src={readyPlayerMeLogo} alt='white HoloFair logo' />
                  }
                  iconNoBg
                  darkBackdrop
                  iconClassName={'w-[80%]'}
                  image={backgroundImage}
                />
              }
              onClick={() => cardOnClick('readyPlayerMe')}
            />

            <NewWorldOptionCard
              actionText='Library'
              image={
                <CardImage
                  icon={<img src={whiteLogo} alt='Ready player me text logo' />}
                  iconNoBg
                  darkBackdrop
                  iconClassName={'w-[80%]'}
                  image={backgroundImage}
                />
              }
              onClick={() => cardOnClick('avatars')}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

ChooseAvatarModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ChooseAvatarModal
