import avatar1 from '../assets/images/avatars/avatar-1.png'
import avatar2 from '../assets/images/avatars/avatar-2.png'
import avatar3 from '../assets/images/avatars/avatar-3.png'
import avatar4 from '../assets/images/avatars/avatar-4.png'
import avatar5 from '../assets/images/avatars/avatar-5.png'

export const avatars = [
  {
    gender: 'male',
    image: avatar1,
  },
  {
    gender: 'male',
    image: avatar2,
  },
  {
    gender: 'male',
    image: avatar3,
  },
  {
    gender: 'male',
    image: avatar4,
  },
  {
    gender: 'female',
    image: avatar5,
  },
  {
    gender: 'male',
    image: avatar1,
  },
  {
    gender: 'male',
    image: avatar2,
  },
  {
    gender: 'male',
    image: avatar3,
  },
  {
    gender: 'male',
    image: avatar4,
  },
  {
    gender: 'female',
    image: avatar5,
  },
  {
    gender: 'male',
    image: avatar1,
  },
  {
    gender: 'male',
    image: avatar2,
  },
  {
    gender: 'male',
    image: avatar3,
  },
  {
    gender: 'male',
    image: avatar4,
  },
  {
    gender: 'female',
    image: avatar5,
  },
  {
    gender: 'male',
    image: avatar1,
  },
  {
    gender: 'male',
    image: avatar2,
  },
  {
    gender: 'male',
    image: avatar3,
  },
  {
    gender: 'male',
    image: avatar4,
  },
  {
    gender: 'female',
    image: avatar5,
  },
]

export const maleAvatars = avatars.filter((a) => a.gender === 'male')

export const femaleAvatars = avatars.filter((a) => a.gender === 'female')
