import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Modal.module.css'
import { useEffect } from 'react'

const Modal = ({
  open,
  onClose,
  size,
  opacity,
  color,
  className,
  children,
  ...props
}) => {
  useEffect(() => {
    //close the modal when clicking the ESC key
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEscape)
    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [onClose])

  return (
    <div
      className={classNames(styles.root, { [styles.hide]: !open }, [
        styles[`opacity${opacity}`],
      ])}
      onClick={onClose}
      {...props}
    >
      <div
        className={classNames(
          styles.inner,
          styles[color],
          styles[size],
          className
        )}
        //to prevent the modal from closing when clicking on the backdrop
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
    'xxxl',
    'xxxxl',
    'xxxxxl',
    'xxxxxxl',
    'xxxxxxxl',
    'full',
  ]),
  opacity: PropTypes.oneOf([
    '0',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
    '95',
    '100',
  ]),
  color: PropTypes.oneOf(['primary', 'white', 'dark']),
}

Modal.defaultProps = {
  open: false,
  onClose: () => {},
  children: null,
  size: 'xxl',
  opacity: '50',
  color: 'white',
}

export default Modal
