import { forwardRef } from 'react'
import styles from './ModalBody.module.css'
import classNames from 'classnames'

const ModalBody = forwardRef(({ children, ...props }, ref) => {
  return (
    <div
      className={classNames(styles.root, 'overflow-y-auto')}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  )
})

export default ModalBody
